import React, { useEffect, useState, useRef, useCallback } from "react";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import CodeMirror from "@uiw/react-codemirror";
import { autocompletion } from "@codemirror/autocomplete";
import { stex } from "@codemirror/legacy-modes/mode/stex";
import { StreamLanguage } from "@codemirror/language";
import { basicSetup } from "@uiw/codemirror-extensions-basic-setup";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";
import { keymap } from "@codemirror/view";
import { Decoration } from "@codemirror/view";
import ToolbarPdf from "../PdfPreview/ToolbarPdf.js";
import { onlyUnique } from "../../Functions/liboFunction.js";
import {
  getCaretCoordinates,
  saveCursor,
  restoreCursor,
} from "../../Functions/liboFunction.js";
import { getAutoComplete } from "../../Requests/Requests.js";
import "./LiboLaTeXEditor.css";
import classes from "../MSKEditor/LiboEditor.module.css";
import latexEditorClass from "./LiboLaTeXEditor.module.css";
import FormulaDialog from "../MSKEditor/Components/FormulaDialog/FormulaDialog.js";
import {
  FindLaTeXParagraph,
  getLaTeXSelection,
  myCompletions,
  latexCommon,
  countLatexWord,
} from "./LiboLaTeXFunc.js";
import { toast } from "react-toastify";
import Citation from "../MSKEditor/Components/FloatSuggestion/citation.js";
import LiboberryAIAssistents from "../LiboberryAIAssistents/LiboberryAIAssistents.js";
import LaTeXToolbar from "./component/LaTeXToolbar.js";
import { handleShortCutKey } from "../../Functions/editorShortcut.js";
import PdfPreview from "../PdfPreview/PdfPreview.js";
import { undo, redo } from "@codemirror/commands";
import LoadPaperIcon from "../Icons/LoadPaperIcon/LoadPaperIcon.js";
function LiboLaTeXEditor(props) {
  const [
    closeAiAskOnAiLiboberryAiAssistent,
    setCloseAiAskOnAiLiboberryAiAssistent,
  ] = useState(true);

  const [decorations, setDecorations] = useState(Decoration.none);
  const [widgetIndex, setWidgetIndex] = useState(null);
  const [citeParageraph, setCiteParageraph] = useState("");
  const [latexValue, setLatexValue] = useState(props.value);
  const [openCitation, setOpenCitation] = useState(false);
  const [citation_dataSug, setCitation_dataSug] = useState([]);
  const [citationElement, setCitationElement] = useState(<div></div>);
  const [citations, setCitations] = useState([]);
  const [oldCiteParageraph, setOldCiteParageraph] = useState("");
  const [currentPageNO, setCurrentPageNO] = useState(1);
  const [pdfZoom, setPdfZoom] = useState(100);
  const [pdfPageNumbes, setPdfPageNumbes] = useState(1);
  const [isOpenSpiliter, setIsOpenSpiliter] = useState(true);

  const [showPanelAi, setShowPanelAi] = useState(false);
  const [showAiSuggestion, setShowAiSuggestion] = useState(false);
  const [aiOpenPanel, setAiOpenPanel] = useState(false);
  const [aiContent, setAiContent] = useState("");
  const [aiResult, setAiResult] = useState(null);
  const [showAIInterval, setShowAIInterval] = useState(null);
  const [aiSuggestionPropperty, setAiSuggestionPropperty] = useState({
    left: null,
    top: null,
    bottom: null,
    showsubMenuLeft: true,
    position: null,
  });
  const [autoComplete, setAutoComplete] = useState([
    {
      intervalAiAutoComplete: null,
    },
  ]);

  const [fullWidthClass, setFullWidthClass] = useState(
    classes.showEditorFullWidth
  );
  const [normalWidthClass, setNormalWidthClass] = useState(
    classes.showEditorFullWidth
  );
  const [isInsertFormulaModelOpen, setIssInsertFormulaModelOpen] =
    useState(false);
  const [pdfText, setPdfText] = useState("");
  const editorRef = useRef(null);

  const buildSuffixArray = (text) => {
    const suffixes = Array.from({ length: text.length }, (_, i) => i);
    return suffixes.sort((a, b) => text.slice(a).localeCompare(text.slice(b)));
  };
  const searchText = (text, pattern) => {
    const suffixArray = buildSuffixArray(text);
    let left = 0;
    let right = suffixArray.length - 1;

    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const suffix = text.slice(suffixArray[mid]);

      if (suffix.startsWith(pattern)) {
        return suffixArray[mid];
      } else if (pattern < suffix) {
        right = mid - 1;
      } else {
        left = mid + 1;
      }
    }

    return -1;
  };
  const GetRangeSelectionText = (prevSelection) => {
    let range = prevSelection;
    let el = document.createElement("div");
    el.appendChild(range.cloneContents());
    let selectedText = "";
    for (let child of el.childNodes) {
      selectedText +=
        (child.nodeType !== Node.TEXT_NODE
          ? child.innerText
          : child.parentNode.innerText.toString()) + " ";
    }

    return selectedText;
  };
  function getSelectedText() {
    const selection = window.getSelection();
    let selectedText = "";

    // Loop through each range in the selection
    for (let i = 0; i < selection.rangeCount; i++) {
      selectedText += GetRangeSelectionText(selection.getRangeAt(i)) + " ";
    }

    // Trim the trailing space
    return selectedText.trim();
  }
  const findPdfStartIndex = () => {
    let index = 0;
    let span = window.getSelection().getRangeAt(0).startContainer.parentNode;
    let divStart = span.parentNode;
    while (
      divStart != null &&
      divStart.tagName != "DIV" &&
      divStart.className != "react-pdf__Page__textContent"
    ) {
      divStart = divStart.parentNode;
      if (divStart.tagName == "SPAN") {
        span = divStart;
      }
    }
    if (divStart != null) {
      for (let child of divStart.childNodes) {
        if (child == span) {
          break;
        }
        index +=
          child.nodeType == Node.TEXT_NODE ? 0 : child.innerText.trim().length;
      }
      let parent = divStart.parentNode;
      while (
        parent != null &&
        parent.tagName != "DIV" &&
        parent.className != "react-pdf__Document"
      ) {
        parent = parent.parentNode;
      }
      if (parent != null) {
        let query = parent.querySelectorAll(".react-pdf__Page__textContent");
        for (let child of query) {
          if (child == divStart) {
            break;
          }
          index += child.innerText.trim().length;
        }
      }
    }
    return index;
  };
  // Debounced handler for text selection
  const handleTextSelection = useCallback(
    debounce(() => {
      if (isOpenSpiliter) {
        const selection = getSelectedText();

        const selectedText = selection.toString().toLowerCase().trim();
        if (selectedText) {
          const pdfIndex = findPdfStartIndex();
          const editorIndex = latexValue
            .toLowerCase()
            .indexOf(selectedText, pdfIndex);

          if (editorIndex !== -1) {
            setCurserPositionEditor(editorIndex, editorIndex);

            scrollToEditorActiveLine();
          }
        }
      }
    }, 300),
    [pdfText, latexValue]
  );

  const scrollToEditorActiveLine = () => {
    //
    if (editorRef.current) {
      const lines =
        editorRef.current.view.dom.querySelectorAll(".cm-activeLine");

      if (lines.length > 0) {
        lines[0].scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }
    }
  };
  const handleOpenFormulaModel = () => {
    setIssInsertFormulaModelOpen(true);
  };
  const handleCloseFormulaModel = () => {
    setIssInsertFormulaModelOpen(false);
  };

  const addAiAutoCompleteOnEditor = (txt, interval, from) => {
    try {
      let selectCursorPosition = window.selectCursorPosition;
      if (
        interval == autoComplete[0].intervalAiAutoComplete &&
        selectCursorPosition != null
      ) {
        restoreCursor(selectCursorPosition.prevCursor);

        if (checkAllowAiAutoComplete("AIAutoComplete", false)) {
          let ReferenceObj = document.createElement("aiautocomplete");

          ReferenceObj.setAttribute("data-text", txt);
          ReferenceObj.innerHTML = txt;
          ReferenceObj.setAttribute("data-pos", from);
          let panelbox = document.createElement("span");
          panelbox.className = "panelbox";
          let saveBox = document.createElement("span");
          saveBox.className = "saveBox";
          saveBox.addEventListener("click", (e) => {
            let parageraph = txt;
            // console.log(from);
            insertTextAtCursor(parageraph, false, from);
            removeAiAutoComplete();
          });
          let tabBox = document.createElement("span");
          tabBox.className = "tabBox";
          saveBox.append(tabBox);

          let DismissBox = document.createElement("span");
          DismissBox.className = "DismissBox";
          let EscBox = document.createElement("span");
          EscBox.className = "EscBox";
          DismissBox.addEventListener("click", (e) => {
            ClearAiAutocomplete();
          });
          DismissBox.append(EscBox);

          panelbox.append(saveBox);
          panelbox.append(DismissBox);
          ReferenceObj.append(panelbox);
          const customDecoration = Decoration.widget({
            widget: ReferenceObj,
            side: 1, // Position it to the right of the cursor
          });

          const newDecorations = Decoration.set([
            customDecoration.range(selectCursorPosition.cursuerPos.from),
          ]); // Position at character index 5
          setDecorations(newDecorations);

          if (editorRef.current) {
            const view = editorRef.current.view;
            // console.log(view, newDecorations, customDecoration.range(from));
            view.dispatch({
              effects: view.state.field(Decoration, false).reconfigure({
                decorations: newDecorations,
              }),
            });
          }
        } else {
          removeAiAutoComplete();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  const ClearAiAutocomplete = (except = null) => {
    try {
      let auto = autoComplete;
      let old = auto.pop();
      clearTimeout(old.intervalAiAutoComplete);
      setDecorations(Decoration.none);
      auto.push({
        intervalAiAutoComplete: null,
      });
      setAutoComplete(auto);
    } catch {}
  };
  const removeAiAutoComplete = () => {
    try {
      if (autoComplete[0].intervalAiAutoComplete != null) {
        ClearAiAutocomplete();
      }
      clearTimeout(showAIInterval);
      setShowAIInterval(null);
      setShowPanelAi(false);
      setShowAiSuggestion(false);
      props.handleShowLiboberryAiAssistence(false);
    } catch {}
  };
  const callAiAutoComplete = (Parageraph, timer) => {
    let intervalAiAutoComplete = setTimeout(() => {
      getAutoComplete(props.projectInfo?.id, { paragraph: Parageraph })
        .then((res) => {
          if (res.status >= 200 && res.status <= 299) {
            let auto = autoComplete;
            if (res.data.completed_paragraph != null) {
              // console.log(res.data.completed_paragraph);
              addAiAutoCompleteOnEditor(
                res.data.completed_paragraph,
                intervalAiAutoComplete,
                window.selectCursorPosition.cursuerPos.from
              );
              setAutoComplete(auto);
            } else toast.warn("AI Suggestion Is Empty");
            removeAiAutoComplete();
          }
        })
        .catch((err) => {
          removeAiAutoComplete();
          console.log(err, err.response);
          let auto = autoComplete;
          auto[0].AIresult = "";
          setAutoComplete(auto);

          toast.error(err.response.data.error);
        });
    }, timer);
    return intervalAiAutoComplete;
  };
  const showAiAutoComplete = (workwithtimer = true) => {
    removeAiAutoComplete();
    if (workwithtimer) {
      let prevCursor = saveCursor();
      let intervalid = setTimeout(() => {
        if (window.getSelection().getRangeAt(0) == prevCursor) {
          setShowAIInterval(null);
          setPosAiIcon();
        }
      }, 700);
      setShowAIInterval(intervalid); //intervalid;
    } else {
      setPosAiIcon();
    }
  };
  const checkAllowAiAutoComplete = (type, isSelection = false) => {
    let allow = window.selectCursorPosition
      ? window.selectCursorPosition.cursuerPos == null
        ? false
        : window.selectCursorPosition.cursuerPos.from != null
      : false;
    if (allow == false) {
      return false;
    }

    let from = window.selectCursorPosition.cursuerPos.from;
    let to = window.selectCursorPosition.cursuerPos.to;
    if (type == "AIAutoComplete") {
      return isSelection ? from != to : from == to;
    } else if (type == "Citations") {
      return true;
    } else if (type == "AI") {
      if (isSelection) {
        if (from != to) {
          return true;
        } else {
          let parageraph = "";
          let line = getLineParageraph();
          if (line) {
            parageraph = getLaTeXSelection(line.text, 0, line.text.length);
          }
          return !(
            parageraph == null ||
            parageraph.trim() == "" ||
            parageraph.trim() == "[journal]{article}"
          );
        }
      } else {
        return from == to;
      }
    }

    return allow;
  };
  const setPosAiIcon = (panel = null) => {
    let position = null;
    let showsubMenuLeft = true;
    let left = null;
    let top = null;
    let bottom = null;
    if (panel == "bottomMenu") {
      left =
        props.isShowSettingPannel && document.body.offsetWidth > 1112
          ? "calc(50% - 335px)"
          : "calc(50% - 185px)";
      bottom = "40px";
      position = "fixed";
      top = "inherit";
    } else {
      let pos = getCaretCoordinates();
      let spliter = document.getElementsByClassName("layout-pane-primary")[0];
      left = pos.x - 30;
      top = spliter.scrollTop + pos.y - 92;
      let marginTop = Number(editorRef.current?.editor.offsetTop);
      let marginBot = 0;
      // Number(
      //   editorRef.current?.style.marginBottom.replace("px", "")
      // );
      showsubMenuLeft = true;
      if (panel == "aipanel") {
        let aiPanelLeft = editorRef.current?.editor.offsetWidth - (left + 190);
        showsubMenuLeft = aiPanelLeft - 180 > 0;
        aiPanelLeft = aiPanelLeft > 0 ? left : left + aiPanelLeft;

        let aiPanelTop =
          editorRef.current?.editor.offsetHeight +
          marginBot +
          marginTop -
          (top + 306);

        aiPanelTop = aiPanelTop > 0 ? top : top + aiPanelTop;
        top = aiPanelTop;
        left = aiPanelLeft;
      }
      left = left + "px";
      top = top + "px";
    }

    setShowAiSuggestion(true);
    setAiSuggestionPropperty({
      left: left,
      top: top,
      bottom: bottom,
      showsubMenuLeft: showsubMenuLeft,
      position: position,
    });
  };
  const setCurserPosition = () => {
    window.selectCursorPosition = {
      prevCursor: saveCursor(),
      cursuerPos: getCurserPosition(),
    };
  };

  useEffect(() => {
    if (props.showLiboberryAiAssistence) {
      try {
        if (autoComplete[0].intervalAiAutoComplete != null) {
          ClearAiAutocomplete();
        }
        setPosAiIcon("bottomMenu");
        clearTimeout(showAIInterval);
        setShowAIInterval(null);
        setShowPanelAi(true);
        setShowAiSuggestion(true);
      } catch {}
    } else {
      setShowAiSuggestion(false);
    }
  }, [props.showLiboberryAiAssistence]);

  const BindAiPanel = () => {
    RemoveAiPanel();
    ClearAiAutocomplete();
    // let element = getAiElement();

    // if (element == null) {
    //   RemoveAiPanel();
    //   ClearAiAutocomplete();
    //   removeAiAutoComplete();
    // } else {
    //   setAiRefProperty(element);

    let index = getCurserPosition();
    if (index == null) {
      setAiContent(null);
    } else {
      let parageraph = "";
      if (index.from == index.to) {
        let line = getLineParageraph();
        if (line) {
          parageraph = getLaTeXSelection(line.text, 0, line.length);
        }
      } else {
        parageraph = getLaTeXSelection(latexValue, index.from, index.to);
      }
      setAiContent(parageraph);
    }

    setAiOpenPanel(true);
    setAiResult(null);
    removeAiAutoComplete();
    // }
  };
  const getLineParageraph = () => {
    if (editorRef.current) {
      const view = editorRef.current.view;
      const { from, to } = view.state.selection.main; // Get the current selection
      return view.state.doc.lineAt(from); // Get the line at the cursor
      // const lineNumber = line.number; // Line number
      // lineText = line.text; // Text of the line
    }
    return null;
  };
  const RemoveAiPanel = () => {
    setAiOpenPanel(false);
    setAiResult(null);
  };
  const InsertFormulaInEditor = (value, label, inline, Html) => {
    let result = "";

    if (inline) {
      result = "\\(" + value + "\\)\\label{" + label + "}";
    } else {
      result = "\\begin{equation}\n";
      result += "\\begin{split}\n";
      result += "\\label{" + label + "}\n";
      result += value;
      result += "\n\\end{split}\n";
      result += "\\end{equation}\n";
    }

    return result;
  };
  const customKeymap = [
    {
      key: "Mod-Z",
      run: undo,
    },
    {
      key: "Mod-Space",
      run: (view) => {
        view.autocompletion();
        return true;
      },
    },
    {
      key: "\\",
      run: (view) => {
        view.autocompletion();
        return true;
      },
    },
  ];

  const closeCitattion = () => {
    setOpenCitation(false);
    setCiteParageraph("");
    setCitation_dataSug([]);
    setOldCiteParageraph("");
  };

  const closeFloatSuggestion = () => {
    closeCitattion();
  };
  const ReplaceTextToEditor = (text) => {
    if (editorRef.current) {
      const editorView = editorRef.current.view; // Access the CodeMirror editor instance

      if (editorView) {
        const state = editorView.state;

        if (state) {
          let { from, to } = state.selection.main; // Get the current selection range
          if (from == to) {
            let line = getLineParageraph();
            if (line) {
              let parageraph = getLaTeXSelection(
                line.text,
                0,
                line.text.length
              );
              if (
                !(
                  parageraph == null ||
                  parageraph.trim() == "" ||
                  parageraph.trim() == "[journal]{article}"
                )
              ) {
                from = line.from;
                to = line.to;
              }
            }
          }
          let newText = text;
          let newDoc =
            state.doc.sliceString(0, from) +
            newText +
            state.doc.sliceString(to);
          // Use the onChange prop to update the value
          props.onChange(newDoc);
          setLatexValue(newDoc);
          // Create a new transaction to set the new document
          editorView.dispatch({
            changes: { from: 0, to: state.doc.length, insert: newDoc },
            selection: {
              anchor: from + newText.length,
              head: from + newText.length,
            }, // Set new cursor position
          });
        }
      }
    }
  };
  const insertTextAtCursor = (
    text,
    setselection = false,
    newFromPos = null
  ) => {
    if (editorRef.current) {
      const editorView = editorRef.current.view; // Access the CodeMirror editor instance

      if (editorView) {
        const state = editorView.state;

        if (state) {
          const { from, to } = state.selection.main; // Get the current selection range
          if (newFromPos != null) {
            from = newFromPos;
            to = newFromPos;
          }
          let newText = "";
          if (setselection === false) {
            newText = text;
          } else {
            newText = text + "{" + state.doc.sliceString(from, to) + "}";
          }
          let newDoc =
            state.doc.sliceString(0, from) +
            newText +
            state.doc.sliceString(to);
          // Use the onChange prop to update the value
          props.onChange(newDoc);
          setLatexValue(newDoc);
          // Create a new transaction to set the new document
          editorView.dispatch({
            changes: { from: 0, to: state.doc.length, insert: newDoc },
            selection: {
              anchor: from + newText.length,
              head: from + newText.length,
            }, // Set new cursor position
          });
        }
      }
    }
  };
  const setCurserPositionEditor = (from, to) => {
    if (editorRef.current) {
      const editorView = editorRef.current.view; // Access the CodeMirror editor instance

      if (editorView) {
        editorView.dispatch({
          selection: {
            anchor: from,
            head: from,
          }, // Set new cursor position
        });
      }
    }
  };
  const setEditorValueChange = () => {
    if (editorRef.current) {
      const editorView = editorRef.current.view; // Access the CodeMirror editor instance

      if (editorView) {
        const state = editorView.state;

        if (state) {
          const { from, to } = state.selection.main; // Get the current selection range
          // Use the onChange prop to update the value
          let text = state.doc.sliceString(0);
          props.onChange(text);
          setLatexValue(text);
          // Create a new transaction to set the new document
          editorView.dispatch({
            changes: { from: 0, to: state.doc.length, insert: text },
            selection: {
              anchor: from,
              head: from,
            }, // Set new cursor position
          });
          return text;
        }
      }
    }
    return null;
  };

  const getCurserPosition = () => {
    let index = { from: null, to: null };
    if (editorRef.current) {
      const editorView = editorRef.current.view; // Access the CodeMirror editor instance

      if (editorView) {
        const state = editorView.state;
        if (state) {
          index.from = state.selection.main.from;
          index.to = state.selection.main.to;
        }
      }
    }
    return index;
  };

  let saveIntervalId = null;
  const getDocumentData = () => {
    let result = setEditorValueChange();
    return result == null ? latexValue : result;
  };
  const saveWithToast = (e) => {
    let value = handleShortCutKey(e);
    if (value == "save") {
      let latex = getDocumentData();
      e.preventDefault();
      props.onSave(latex, true, true);
    }
  };
  useEffect(() => {
    setLatexValue(props.value);
    window.addEventListener("keydown", saveWithToast);
    saveIntervalId = setInterval(() => {
      let latex = getDocumentData();
      props.onSave(latex, false, true);
    }, 60000);

    return () => {
      window.removeEventListener("keydown", saveWithToast);
      window.Md5LastValue = null;
      if (saveIntervalId) {
        clearInterval(saveIntervalId);
      }
      props.onSave(latexValue);
    };
  }, []);
  useEffect(() => {
    setLatexValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.showPlagiarism) {
      let index = getCurserPosition();
      if (index == null) {
        toast.warn("Please select parageraph");
        props.handleShowPlagiarism(false, "");
      } else {
        let parageraph = "";
        if (index.from == index.to) {
          let line = getLineParageraph();
          if (line) {
            parageraph = getLaTeXSelection(line.text, 0, line.text.length);
          }
          if (
            parageraph == null ||
            parageraph.trim() == "" ||
            parageraph.trim() == "[journal]{article}"
          ) {
            toast.warn("Please select parageraph");
            props.handleShowPlagiarism(false, "");
          } else {
            props.handleShowPlagiarism(true, parageraph);
          }
        } else {
          parageraph = getLaTeXSelection(latexValue, index.from, index.to);
          if (parageraph.trim() == "") {
            toast.warn("Please select parageraph");
            props.handleShowPlagiarism(false, "");
          } else {
            props.handleShowPlagiarism(true, parageraph);
          }
        }
      }
    }
  }, [props.showPlagiarism]);

  useEffect(() => {
    removeAiAutoComplete();
    // console.log(latexValue);
    props.wordCount(countLatexWord(latexValue));
  }, [latexValue]);

  useEffect(() => {
    if (props.needUpdateLatexValue == true) {
      props.setNeedUpdateLatexValue(false);
      props.handleNeedUpdateLatexValue(latexValue);
    }
  }, [props.needUpdateLatexValue]);

  useEffect(() => {
    if (props.oImage && props.oImage != null && props.oImage != "") {
      let id = props.oImage.split(",")[0];
      let url = props.oImage.split(",")[1];
      let Latex = "\\begin{figure}[h] \n";
      Latex += "\\centering \n";
      Latex += "\\includegraphics[width=0.5\\linewidth]{" + url + "} \n";
      Latex += "\\caption{Image Caption} \n";
      Latex += "\\label{Figure_" + id + "} \n";
      Latex += "\\end{figure} \n ";
      insertTextAtCursor(Latex);
    }
    props.onSetImage("");
  }, [props.oImage]);

  useEffect(() => {
    setFullWidthClass(classes.showEditorFullWidth);
    setNormalWidthClass(classes.showSettingOnEditor);
  }, [props.isShowSettingPannel]);

  useEffect(() => {
    setCitation_dataSug(props.citation_data.suggestion);
  }, [props.citation_data]);

  const handleUndo = () => {
    if (editorRef.current) {
      undo(editorRef.current.view);
    }
  };

  const handleRedo = () => {
    if (editorRef.current) {
      redo(editorRef.current.view);
    }
  };
  useEffect(() => {
    if (
      props.fullWidthClass == "NoAnimation" ||
      props.normalWidthClass == "NoAnimation"
    ) {
      setFullWidthClass(classes.showEditorFullWidth_NoAnimation);
      setNormalWidthClass(classes.showSettingOnEditor_NoAnimation);
    }
  }, [props.fullWidthClass, props.normalWidthClass]);

  useEffect(() => {
    if (props.showCitation) {
      let index = getCurserPosition();
      if (index == null) {
        toast.warn("Please specify the location");
      } else {
        let parageraph = "";
        if (index.from == index.to) {
          let line = getLineParageraph();
          if (line) {
            parageraph = getLaTeXSelection(line.text, 0, line.text.length);
          }

          setCiteParageraph(parageraph);
        } else {
          parageraph = getLaTeXSelection(latexValue, index.from, index.to);
          setCiteParageraph(parageraph);
        }
        setOpenCitation(true);
      }
    }
    props.onShowCitation(false);
  }, [props.showCitation]);

  const extensions = [
    basicSetup({
      lineWrapping: true,
      smartIndent: true,
      lineNumbers: true,
      foldGutter: true,
      autoCloseTags: true,
      matchBrackets: true,
      autoCloseBrackets: true,
      autoRefresh: true,
      // extraKeys: { "\": "autocomplete" },
    }),
    autocompletion({
      override: [myCompletions],
    }),
    StreamLanguage.define(stex),
    keymap.of([...customKeymap]),
  ];

  return (
    <div
      className={
        latexEditorClass.LatexEditor +
        " " +
        (props.isShowSettingPannel ? normalWidthClass : fullWidthClass)
      }
    >
      <SplitterLayout
        style={{ height: "" }}
        percentage={true}
        secondaryInitialSize={30}
        primaryMinSize={30}
        secondaryMinSize={10}
        customClassName={
          props.isShowSettingPannel ? normalWidthClass : fullWidthClass
        }
      >
        <div>
          <LaTeXToolbar
            onFilesUploadButtonClick={props.onFilesUploadButtonClick}
            onProjectFileDelete={props.onProjectFileDelete}
            projectFiles={props.projectFiles}
            handleIsOpenSpiliter={setIsOpenSpiliter}
            onChange={(value, isSelection = false) => {
              insertTextAtCursor(value, isSelection);
            }}
            ExportLiboberryGenerator={(value) => {
              props.ExportLiboberryGenerator(value);
            }}
            onAddImage={props.onSetImage}
            handleOpenShareDialog={props.handleOpenShareDialog}
            handleClickOpenFormulaModel={handleOpenFormulaModel}
            Undo={handleUndo}
            Redo={handleRedo}
            projectId={props.projectInfo?.id}
            handleOnSaveClick={(
              showToast = true,
              update_pdf = true,
              IsRegenaratePdf = false
            ) => {
              setEditorValueChange();
              props.onSave(latexValue, showToast, update_pdf, IsRegenaratePdf);
            }}
          />
          {openCitation && (
            <Citation
              bindDocumentCitations={props.bindDocumentCitations}
              onCloseFloatSuggestion={() => {
                closeFloatSuggestion();
              }}
              onClickAITab={() => {
                if (
                  citation_dataSug.length == 0 ||
                  citeParageraph != oldCiteParageraph
                ) {
                  props.checkCitations(citeParageraph);
                  setOldCiteParageraph(citeParageraph);
                  return true;
                }
                return false;
              }}
              load_data_sug={props.load_data_sug}
              // closeFilter={randomNumberForCloseFilter}
              Element={citationElement}
              dataSug={citation_dataSug}
              documentRefrence={props.documentCitation}
              onDocumentRefrence={props.setDocumentCitation}
              ProjectId={props.projectInfo?.id}
              citationIds={citations}
              onCitationAcceptClick={(element, citationIds) => {
                if (citationIds.length > 0) {
                  let insertText =
                    " \\cite{" +
                    citationIds
                      .map((a) => {
                        return a.bibtex;
                      })
                      .join(", ") +
                    "}";
                  insertTextAtCursor(insertText);
                  return true;
                } else {
                  return false;
                }
              }}
              onDismissClick={(element) => {
                closeFloatSuggestion();
              }}
              content={citeParageraph}
            />
          )}

          <CodeMirror
            onKeyDown={(e) => {
              removeAiAutoComplete();
              let value = handleShortCutKey(e);
              if (value == "Citations") {
                e.preventDefault();
                props.onShowCitation(true);
                return false;
              }
              if (value == "AI") {
                e.preventDefault();
                props.handleShowLiboberryAiAssistence(true);
                return false;
              } else if (value == "save") {
                e.preventDefault();
                return false;
              } else if (value == "InsertFormula") {
                handleOpenFormulaModel();
                e.preventDefault();
                return false;
              } else if (value != "") {
                e.preventDefault();
                insertTextAtCursor(latexCommon(value), true);
                e.preventDefault();
                return false;
              }
            }}
            ref={editorRef}
            onKeyUp={(e) => {
              setCurserPosition();
              if (checkAllowAiAutoComplete() && e.which != 27) {
                if (props.showLiboberryAiAssistence == false) {
                  showAiAutoComplete();
                }
              } else {
                removeAiAutoComplete();
              }
            }}
            onClick={(e) => {
              setCurserPosition();
              removeAiAutoComplete();
              setPosAiIcon();
            }}
            onCursorActivity={(e) => {
              console.log(e);
            }}
            onChange={(value) => {
              setLatexValue(value);
            }}
            // theme="dark"
            value={props.value}
            minHeight="100%"
            width="100%"
            extensions={extensions}
            lineWrapping={true}
            decorations={decorations} // Apply decorations here
          />

          <LiboberryAIAssistents
            bookmarkLabelsFilter={props.bookmarkLabelsFilter}
            setBookmarkLabelsFilter={props.setBookmarkLabelsFilter}
            setPrompts={props.setPrompts}
            prompts={props.prompts}
            setPromptLabels={props.setPromptLabels}
            promptLabels={props.promptLabels}
            userInfo={props.userInfo}
            setAiContent={setAiContent}
            liboAiHistory={props.liboAiHistory.filter(
              (s) => s.editor == "latex"
              // && s.show == true
            )}
            ClearHistory={(item) => {
              let list =
                item == null
                  ? [
                      ...props.liboAiHistory.filter(
                        (s) => !(item.editor == "latex")
                      ),
                    ]
                  : [
                      ...props.liboAiHistory.filter(
                        (s) =>
                          !(
                            item.editor == s.editor &&
                            item.number == s.number &&
                            item.id == s.id
                          )
                      ),
                    ];

              props.setLiboAiHistory([...list]);
            }}
            AddBookMark={(item) => {
              let list = props.liboAiHistory.map((s) => {
                if (
                  (item.editor == s.editor &&
                    item.number == s.number &&
                    item.id == s.id) ||
                  (item.bookmarkId != null && item.bookmarkId == s.bookmarkId)
                ) {
                  return {
                    ...s,
                    bookmark: true,
                    bookmarkId: item.bookmarkId,
                    bookmarkTitle: item.bookmarkTitle,
                    bookmarkTags: item.bookmarkTags,
                  };
                }

                return { ...s };
              });

              props.setLiboAiHistory([...list]);
            }}
            removeBookMark={(value) => {
              let list = props.liboAiHistory.map((s) => {
                if (s.type == "ask" && s.bookmarkId == value) {
                  return {
                    ...s,
                    bookmark: false,
                    bookmarkId: null,
                    bookmarkTitle: "",
                    bookmarkTags: [],
                  };
                }
                return { ...s };
              });
              props.setLiboAiHistory([...list]);
            }}
            ShowAllHistory={() => {
              let list = props.liboAiHistory.map((s) => {
                return { ...s, show: true };
              });

              props.setLiboAiHistory([...list]);
            }}
            AddLiboAiHistory={(values, ShowAllHistory = false) => {
              for (let value of values) {
                value.id = "";
                value.editor = "latex";
                value.number = Math.random(10000000);
              }
              let list = [...props.liboAiHistory, ...values];
              if (ShowAllHistory) {
                list = list.map((s) => {
                  return { ...s, show: true };
                });
              }
              props.setLiboAiHistory([...list]);
            }}
            aiElement={<div></div>}
            editor={"CE"}
            setCloseAiAskOnAiLiboberryAiAssistent={
              setCloseAiAskOnAiLiboberryAiAssistent
            }
            closeAiAskOnAiLiboberryAiAssistent={
              closeAiAskOnAiLiboberryAiAssistent
            }
            projectid={props.projectInfo?.id}
            AiDismissClick={() => {
              RemoveAiPanel();
            }}
            AiReplaceClick={(value = null) => {
              let result =
                value == null || value.trim() == "" ? aiResult : value;
              ReplaceTextToEditor(result);
              RemoveAiPanel();
              return true;
            }}
            AiInsertClick={(value = null) => {
              let result =
                value == null || value.trim() == "" ? aiResult : value;
              insertTextAtCursor(result);
              RemoveAiPanel();
              return true;
            }}
            AskAiApproveClick={(value = null) => {
              let result =
                value == null || value.trim() == "" ? aiResult : value;
              ReplaceTextToEditor(result);
              RemoveAiPanel();
              return true;
            }}
            SelectionStatus={
              getCurserPosition().from == getCurserPosition().to
                ? "Document"
                : "Selection"
            }
            showPanelAi={showPanelAi}
            setShowPanelAi={setShowPanelAi}
            showAiSuggestion={showAiSuggestion}
            handleCloseShowAiSuggestion={(value) => {
              if (value == false) {
                removeAiAutoComplete();
              }
              setShowAiSuggestion(value);
            }}
            aiOpenPanel={aiOpenPanel}
            setAiOpenPanel={setAiOpenPanel}
            handleOpenPanelClick={() => {
              props.onSave(latexValue, false);
              restoreCursor(window.selectCursorPosition?.prevCursor);
              setPosAiIcon("aipanel");
            }}
            setPressEnterChange_tone={() => {}}
            leftPosition={aiSuggestionPropperty.left}
            topPosition={aiSuggestionPropperty.top}
            bottomPosition={aiSuggestionPropperty.bottom}
            position={aiSuggestionPropperty.position}
            CitationClick={() => {
              restoreCursor(window.selectCursorPosition?.prevCursor);
              if (!checkAllowAiAutoComplete("Citations")) {
                toast.warn("Please specify the location of the body text");
                return false;
              }

              setPosAiIcon();
              ClearAiAutocomplete();
              RemoveAiPanel();
              props.onShowCitation(true);
              return true;
            }}
            IntializeAiAssistentRemoveAiPanel={true}
            AiAssistentClick={(value) => {
              restoreCursor(window.selectCursorPosition?.prevCursor);

              return true;
            }}
            getContent={() => {
              let index = getCurserPosition();
              if (
                index == null ||
                (index.from == index.to && index.from == 0)
              ) {
                setAiContent(latexValue);
                return;
              } else {
                let parageraph = "";
                if (index.from == index.to) {
                  let line = getLineParageraph();
                  if (line) {
                    parageraph = getLaTeXSelection(
                      line.text,
                      0,
                      line.text.length
                    );
                  }
                  if (
                    parageraph == null ||
                    parageraph.trim() == "" ||
                    parageraph.trim() == "[journal]{article}"
                  ) {
                    setAiContent(latexValue);
                    return;
                  }
                } else {
                  parageraph = getLaTeXSelection(
                    latexValue,
                    index.from,
                    index.to
                  );
                }

                setAiContent(parageraph);
              }
            }}
            aiResult={aiResult}
            setAiResult={setAiResult}
            disabledAiAutoComplete={true}
            ContinueWritingClick={() => {
              restoreCursor(window.selectCursorPosition?.prevCursor);
              if (!checkAllowAiAutoComplete("AIAutoComplete", false)) {
                toast.warn("Please specify the location of the parageraph");
                return false;
              }
              let Parageraph = FindLaTeXParagraph(
                latexValue,
                getCurserPosition().from,
                true
              );
              if (Parageraph.trim() == "") {
                toast.warn("Please specify the location of the parageraph");
                return false;
              }
              setPosAiIcon();
              ClearAiAutocomplete();
              RemoveAiPanel();

              let intervalid = callAiAutoComplete(Parageraph, 0);
              let auto = autoComplete;
              auto.pop();
              auto.push({
                intervalAiAutoComplete: intervalid,
              });
              setAutoComplete(auto);
              return true;
            }}
            showsubMenuRight={aiSuggestionPropperty.showsubMenuLeft}
            AiParaphraseClick={() => {
              restoreCursor(window.selectCursorPosition?.prevCursor);
              if (!checkAllowAiAutoComplete("AI", true)) {
                toast.warn("Please Select Parageraph");
                return false;
              }
              BindAiPanel();
              return true;
            }}
            AiMakeLongerClick={() => {
              restoreCursor(window.selectCursorPosition?.prevCursor);
              if (!checkAllowAiAutoComplete("AI", true)) {
                toast.warn("Please Select Parageraph");
                return false;
              }
              BindAiPanel();
              return true;
            }}
            AiMakeSummarizeClick={() => {
              restoreCursor(window.selectCursorPosition?.prevCursor);
              if (!checkAllowAiAutoComplete("AI", true)) {
                toast.warn("Please Select Parageraph");
                return false;
              }
              BindAiPanel();
              return true;
            }}
            AiMakeShorterClick={() => {
              restoreCursor(window.selectCursorPosition?.prevCursor);
              if (!checkAllowAiAutoComplete("AI", true)) {
                toast.warn("Please Select Parageraph");
                return false;
              }
              BindAiPanel();
              return true;
            }}
            AiToneClick={() => {
              restoreCursor(window.selectCursorPosition?.prevCursor);
              return true;
            }}
            ToneSubMenuClick={() => {
              restoreCursor(window.selectCursorPosition?.prevCursor);
              if (!checkAllowAiAutoComplete("AI", true)) {
                toast.warn("Please Select Parageraph");
                return false;
              }
              BindAiPanel();
              return true;
            }}
            ToneCunstomClick={() => {
              restoreCursor(window.selectCursorPosition?.prevCursor);
              if (!checkAllowAiAutoComplete("AI", true)) {
                toast.warn("Please Select Parageraph");
                return false;
              }
              BindAiPanel();
              return true;
            }}
            aiContent={aiContent}
          />
        </div>
        {isOpenSpiliter && (
          <div id="pdfPreviewPanelBox">
            <ToolbarPdf
              currentPageNO={currentPageNO}
              ChangeCurrentPage={setCurrentPageNO}
              handleReGeneratePdf={() => {
                props.onSave(latexValue, false, true, true);
              }}
              handleZoom={(value) => {
                setPdfZoom(value);
              }}
              zoom={pdfZoom}
              PageNUmbers={pdfPageNumbes}
              showPdf={true}
            />
            {props.LaodPdf && (
              <div className={"pdfLoader"}>
                <LoadPaperIcon width={"33%"} />
              </div>
            )}

            <PdfPreview
              handleTextSelection={handleTextSelection}
              setPdfText={setPdfText}
              DisplayPageNO={currentPageNO}
              ChangeDisplayPage={setCurrentPageNO}
              file={props.pdf}
              zoom={pdfZoom}
              setZoom={setPdfZoom}
              setLoadPdf={props.setLoadPdf}
              handlePdfPageNumbes={(value) => {
                setPdfPageNumbes(value);
              }}
            />
          </div>
        )}
      </SplitterLayout>
      {isInsertFormulaModelOpen && (
        <FormulaDialog
          title={"FormulaEditInsertDialog"}
          key={"FormulaEditInsertDialog"}
          open={isInsertFormulaModelOpen}
          handleCloseFormulaModel={handleCloseFormulaModel}
          projectId={props.projectInfo?.id}
          handleClickRemove={() => {
            // removeLatexFormule();
            handleCloseFormulaModel();
          }}
          handleClickAccept={(value, label, inline, Html) => {
            insertTextAtCursor(
              InsertFormulaInEditor(value, label, inline, Html)
            );
            handleCloseFormulaModel();
          }}
          showRemove={false}
          inline={true}
          latexFormula={""}
          label={""}
        />
      )}
    </div>
  );
}

export default LiboLaTeXEditor;

// // export default withRouter(LiboLaTeXEditor);
LiboLaTeXEditor.propTypes = {
  isShowSettingPannel: PropTypes.string,
  fullWidthClass: PropTypes.string,
  normalWidthClass: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSetImage: PropTypes.func,
  oImage: PropTypes.string,
  onSave: PropTypes.func,
  showCitation: PropTypes.bool,
  onShowCitation: PropTypes.func,
  bindDocumentCitations: PropTypes.func,
  documentCitation: PropTypes.arrayOf(PropTypes.object),
  setDocumentCitation: PropTypes.func,
  projectInfo: PropTypes.object,
  pdf: PropTypes.string,
  handleOpenShareDialog: PropTypes.func,
  ExportLiboberryGenerator: PropTypes.func,
  needUpdateLatexValue: PropTypes.bool,
  setNeedUpdateLatexValue: PropTypes.func,
  handleNeedUpdateLatexValue: PropTypes.func,
  showLiboberryAiAssistence: PropTypes.bool,
  handleShowLiboberryAiAssistence: PropTypes.func,
  checkCitations: PropTypes.func,
  citation_data: PropTypes.array,
  load_data_sug: PropTypes.bool,
  showPlagiarism: PropTypes.bool,
  handleShowPlagiarism: PropTypes.func,
  wordCount: PropTypes.func,
  LaodPdf: PropTypes.bool,
  setLoadPdf: PropTypes.func,
  projectFiles: PropTypes.func,
  onProjectFileDelete: PropTypes.func,
  onFilesUploadButtonClick: PropTypes.func,
  liboAiHistory: PropTypes.array,
  setLiboAiHistory: PropTypes.func,
  userInfo: PropTypes.object,
  setPrompts: PropTypes.func,
  prompts: PropTypes.array,
  setPromptLabels: PropTypes.func,
  promptLabels: PropTypes.array,
  bookmarkLabelsFilter: PropTypes.array,
  setBookmarkLabelsFilter: PropTypes.func,
};

LiboLaTeXEditor.defaultProps = {
  isShowSettingPannel: false,
  fullWidthClass: "",
  normalWidthClass: "",
  value: "",
  onChange: () => {},
  onSetImage: () => {},
  oImage: "",
  onSave: () => {},
  showCitation: false,
  onShowCitation: () => {},
  bindDocumentCitations: () => {},
  documentCitation: [],
  setDocumentCitation: () => {},
  projectInfo: null,
  projectFiles: [],
  ExportLiboberryGenerator: () => {},
  handleOpenShareDialog: () => {},
  needUpdateLatexValue: false,
  setNeedUpdateLatexValue: () => {},
  handleNeedUpdateLatexValue: () => {},
  showLiboberryAiAssistence: false,
  handleShowLiboberryAiAssistence: () => {},
  checkCitations: () => {},
  citation_data: () => {},
  load_data_sug: false,
  showPlagiarism: false,
  handleShowPlagiarism: () => {},
  wordCount: () => {},
  LaodPdf: false,
  setLoadPdf: () => {},
  onProjectFileDelete: () => {},
  onFilesUploadButtonClick: () => {},
  liboAiHistory: [],
  setLiboAiHistory: () => {},
  userInfo: null,
  setPrompts: () => {},
  prompts: [],
  setPromptLabels: () => {},
  promptLabels: [],
  bookmarkLabelsFilter: [],
  setBookmarkLabelsFilter: () => {},
};
