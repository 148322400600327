import React from "react";
import PropTypes from "prop-types";
import Mng from "./LabelItem.module.css";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
export default function LabelItem(props) {
  return (
    <span
      className={
        (props.isSelect ? Mng.SearchLabels_select : "") + " " + Mng.Toptag
      }
      style={{
        backgroundColor: `${props.color + "42"}`,
        margin: "5px 5px",
        cursor: props.curserIsPointer ? "pointer" : "default",
        width: props.type == "list" ? "calc(100% - 15px)" : "fit-content",
        borderRadius: props.type == "list" ? "0px" : null,
        display: props.type == "list" ? "flex" : null,
        justifyContent: props.type == "list" ? "space-between" : null,
      }}
    >
      <span
        onClick={props.handleFilterSelectLabel}
        style={{
          maxWidth: props.type == "list" ? "unset" : null,
          width: props.type == "list" ? "100%" : null,
        }}
        className={Mng.spancell}
        title={props.name}
      >
        {props.icon == null ? (
          <span
            className={Mng.circle}
            style={{
              backgroundColor: `${props.color}`,
            }}
          ></span>
        ) : (
          <span
            className={Mng.Icon}
            style={{
              color: `${props.color}`,
            }}
          >
            {props.icon}
          </span>
        )}
        <span
          style={{
            maxWidth: props.type == "list" ? "calc(95% - 20px)" : null,
          }}
          className={Mng.textOverflow}
        >
          {props.name}
        </span>{" "}
        &nbsp;
        {props.hasCount ? "(" + props.count + ")" : " "}
      </span>
      {(props.hasDeleteIcon || props.hasEditIcon) && (
        <span className={Mng.panelButton}>
          {props.hasEditIcon && (
            <EditIcon
              titleAccess="Edit"
              style={{
                color: "#007c34",
                marginRight: "7px",
                borderRadius: "50%",
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={props.onEditClick}
            />
          )}
          {props.hasDeleteIcon && (
            <ClearIcon
              titleAccess="Delete"
              style={{
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={props.onDeleteClick}
            />
          )}
        </span>
      )}
    </span>
  );
}
LabelItem.propTypes = {
  isSelect: PropTypes.bool,
  handleFilterSelectLabel: PropTypes.func,
  color: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number,
  curserIsPointer: PropTypes.bool,
  hasCount: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  hasDeleteIcon: PropTypes.bool,
  hasEditIcon: PropTypes.func,
  onEditClick: PropTypes.bool,
  type: PropTypes.bool,
};
LabelItem.defaultProps = {
  isSelect: false,
  handleFilterSelectLabel: () => {},
  color: "#ffffff",
  name: "label",
  count: 0,
  hasCount: true,
  curserIsPointer: true,
  icon: null,
  hasDeleteIcon: false,
  onDeleteClick: () => {},
  hasEditIcon: false,
  onEditClick: () => {},
  type: "clip",
};
