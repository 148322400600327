import React, { useState } from "react";
import PropTypes from "prop-types";

import "../../../components/LiboBerryCss/liboBerry.css";
import PromptLabels from "./PromptLabels";
import { TextField, InputAdornment, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
function FilterLabels(props) {
  return (
    <>
      {props.isShow && (
        <Dialog
          classes={{ paper: { background: "red" } }}
          open={props.isShow}
          onClose={props.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            style={{
              color: "white",
              backgroundColor: "#eb3449",
            }}
            id="alert-dialog-title"
          >
            {"Filter labels"}
          </DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              minWidth: "320px",
              minHeight: "250px",
            }}
          >
            <PromptLabels
              setPromptLabels={props.setPromptLabels}
              promptLabels={props.promptLabels}
              hasScroll={true}
              scrollMaxHeight={"200px"}
              showUndifineLabel={true}
              curserIsPointer={true}
              hasDeleteIcon={false}
              onDeleteClick={(labelId) => {}}
              hasCount={props.hasCount}
              hasSelect={true}
              isSelectLabel={(labelId) => props.isSelectLabel(labelId)}
              handleSelectLabel={(labelId) => {
                props.handleSelectLabel(labelId);
              }}
              bookmarkList={props.bookmarkList}
              hasEditIcon={false}
              onSaveClick={(id, name, color, mode) => {}}
              typeLabelItem={props.typeLabelItem}
              hasCreate={false}
            />
          </DialogContent>
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                margin: "0px 15px",
              }}
            >
              <Button onClick={props.closeDialog} className={"liboBtnCancel"}>
                Cancel
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
export default FilterLabels;
FilterLabels.propTypes = {
  setPromptLabels: PropTypes.func,
  promptLabels: PropTypes.array,

  hasCount: PropTypes.bool,
  isSelectLabel: PropTypes.func,
  handleSelectLabel: PropTypes.func,
  typeLabelItem: PropTypes.string,
  bookmarkList: PropTypes.array,
  closeDialog: PropTypes.func,
  isShow: PropTypes.bool,
};

FilterLabels.defaultProps = {
  setPromptLabels: () => {},
  promptLabels: [],

  hasCount: false,
  isSelectLabel: () => {
    return false;
  },
  handleSelectLabel: () => {},
  typeLabelItem: "clip",
  bookmarkList: [],
  closeDialog: () => {},
  isShow: false,
};
