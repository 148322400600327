import React, { useState } from "react";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import FilterListIcon from "@material-ui/icons/FilterList";
import { toast } from "react-toastify";
import classes from "./PromptCards.module.css";
import { TextField, InputAdornment } from "@material-ui/core";
import "../../../components/LiboBerryCss/liboBerry.css";
import LabelItem from "../../../components/Tags/LabelItem";
import FilterLabels from "../PromptLabels/FilterLabels";
import BookMarkBox from "../../../Pages/DocumentPage/component/BookMarkBox";
import { filterBookmarkValue } from "../../../Functions/liboFunction";
import { deletePrompts } from "../../../Requests/Requests";
function PromptCards(props) {
  const [searchValue, setSearchValue] = useState("");
  const [showLablesFilter, setShowLablesFilter] = useState(false);
  const [bookMarkItem, setBookMarkItem] = useState(null);
  const [showBookMarkBox, setShowBookMarkBox] = useState(false);

  const isFilterLabel = (labelId) => {
    return (
      props.filterLabels != null && props.filterLabels.some((s) => s == labelId)
    );
  };
  const addOrEditPramps = (bookMarkItem) => {
    if (props.prompts == null || props.prompts.length == 0) {
      props.setPrompts([
        {
          id: bookMarkItem.bookmarkId,
          prompt_text: bookMarkItem.bookmarkText,
          prompt_title: bookMarkItem.bookmarkTitle,
          tags: [...bookMarkItem.bookmarkTags],
        },
      ]);
    } else if (
      props.prompts != null &&
      props.prompts.length > 0 &&
      !props.prompts.some((s) => s.id == bookMarkItem.bookmarkId)
    ) {
      props.setPrompts([
        ...props.prompts,
        {
          id: bookMarkItem.bookmarkId,
          prompt_text: bookMarkItem.bookmarkText,
          prompt_title: bookMarkItem.bookmarkTitle,
          tags: [...bookMarkItem.bookmarkTags],
        },
      ]);
    } else if (
      props.prompts != null &&
      props.prompts.length > 0 &&
      props.prompts.some((s) => s.id == bookMarkItem.bookmarkId)
    ) {
      props.setPrompts([
        ...props.prompts.map((t) => {
          if (t.id == bookMarkItem.bookmarkId) {
            return {
              ...t,
              id: bookMarkItem.bookmarkId,
              prompt_text: bookMarkItem.bookmarkText,
              prompt_title: bookMarkItem.bookmarkTitle,
              tags: [...bookMarkItem.bookmarkTags],
            };
          } else {
            return { ...t };
          }
        }),
      ]);
    }
  };

  const callApiRemovePrompts = (id) => {
    deletePrompts(id)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          props.removeBookMarkFromHistory(id);
          if (res.data.message) {
            toast.success(res.data.message);
          } else {
            toast.success("Prompt has been deleted successfully");
          }
          props.setPrompts([...props.prompts.filter((s) => s.id != id)]);
        }
      })
      .catch((err) => {
        if (err.response.data.detail) {
          toast.warn(err.response.data.detail);
        } else {
          console.log("Seems like there is a problem with the server ", err);
          console.log("err.response", err.response);
          toast.warn(
            "Ops! Seems like there is a problem with the server. Please check it."
          );
        }
      });
  };
  return (
    <>
      <FilterLabels
        setPromptLabels={props.setPromptLabels}
        promptLabels={props.promptLabels}
        hasCount={true}
        isSelectLabel={(labelid) => isFilterLabel(labelid)}
        handleSelectLabel={(labelid) => {
          if (isFilterLabel(labelid)) {
            props.setFilterLabels([
              ...props.filterLabels.filter((s) => s != labelid),
            ]);
          } else {
            props.setFilterLabels([...props.filterLabels, labelid]);
          }
        }}
        typeLabelItem={"clip"}
        bookmarkList={props.prompts}
        closeDialog={() => setShowLablesFilter(false)}
        isShow={showLablesFilter}
      />
      <BookMarkBox
        prompts={props.prompts}
        promptLabels={props.promptLabels}
        setPromptLabels={props.setPromptLabels}
        promptId={bookMarkItem?.bookmarkId}
        promptText={bookMarkItem?.text}
        promptTitle={bookMarkItem?.bookmarkTitle}
        PromptTags={bookMarkItem?.bookmarkTags}
        isShow={showBookMarkBox}
        Close={() => {
          setShowBookMarkBox(false);
        }}
        Save={(data) => {
          setShowBookMarkBox(false);
          addOrEditPramps(data);
          props.AddBookMarkFromHistory(data);
          // let newBookMarkItem = bookMarkItem;
          // newBookMarkItem.bookmarkId = data.bookmarkId;
          // newBookMarkItem.bookmarkTitle = data.bookmarkTitle;
          // newBookMarkItem.bookmarkTags = data.bookmarkTags;
          setBookMarkItem(null);
        }}
      />
      <div
        title={"Create new prompt"}
        className={classes.AddNewBtn + " " + "defaultFontFamily"}
        onClick={(e) => {
          setBookMarkItem(null);
          setShowBookMarkBox(true);
        }}
      >
        Create new Prompt&nbsp;
        <AddIcon
          style={{
            backgroundColor: "#eb3449",
            borderRadius: "100%",
            color: "#ffffff",
            width: "16px",
            height: "16px",
          }}
        />
      </div>
      <div>
        <TextField
          placeholder="Search"
          size="small"
          disabled={false}
          style={{ width: "100%" }}
          InputProps={{
            className: "inputFont inputFontSize0_9em",
            endAdornment: (
              <InputAdornment position="end">
                <div
                  title={"Filter labels"}
                  className={classes.AddLabelBox + " " + "defaultFontFamily"}
                  onClick={(e) => {
                    setShowLablesFilter(true);
                  }}
                >
                  Filter labels&nbsp;
                  <FilterListIcon
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: "100%",
                      color: "#51cc84",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                  {props.filterLabels != null &&
                  props.filterLabels.some(
                    (s) =>
                      s == -1 ||
                      (props.promptLabels != null &&
                        props.promptLabels.some((t) => t.id == s))
                  )
                    ? "(" +
                      props.filterLabels.filter(
                        (s) =>
                          s == -1 ||
                          (props.promptLabels != null &&
                            props.promptLabels.some((t) => t.id == s))
                      ).length +
                      ")"
                    : ""}
                </div>
              </InputAdornment>
            ),
          }}
          value={searchValue}
          onKeyDown={(e) => {
            if (e.which == 13) {
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          onKeyUp={(e) => {
            if (e.which == 13) {
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        ></TextField>
      </div>
      <div>
        {props.prompts && props.prompts.length > 0 && (
          <div>
            {props.prompts
              .filter((bookmarkItem) =>
                filterBookmarkValue(
                  bookmarkItem,
                  searchValue,
                  props.promptLabels,
                  isFilterLabel
                )
              )
              .sort((a, b) => a.prompt_text.localeCompare(b.prompt_text))
              .map((bookmark) => {
                return (
                  <div className={classes.AskBookmarkList_Item}>
                    <div className={classes.AskBookmarkList_ContentPanel}>
                      <div
                        className={classes.AskBookmarkList_Title}
                        title={bookmark.prompt_title}
                      >
                        {bookmark.prompt_title}
                      </div>
                      <div
                        className={classes.AskBookmarkList_Text}
                        title={bookmark.prompt_text}
                      >
                        {bookmark.prompt_text}
                      </div>
                      <div className={classes.AskBookmarkList_labelPanel}>
                        {bookmark.tags != null &&
                          bookmark.tags.length > 0 &&
                          bookmark.tags.map((tagId, index) => {
                            if (
                              props.promptLabels != null &&
                              props.promptLabels.length > 0
                            ) {
                              let labels = [
                                ...props.promptLabels.filter(
                                  (s) => s.id == tagId
                                ),
                              ];
                              if (labels.length > 0) {
                                let label = labels.pop();

                                return (
                                  <>
                                    <LabelItem
                                      curserIsPointer={false}
                                      hasCount={false}
                                      key={
                                        (tagId + " " + bookmark.id,
                                        +" " + index)
                                      }
                                      name={label.tag_name}
                                      color={label.color}
                                    />
                                  </>
                                );
                              }
                            }
                          })}
                        {(bookmark.tags == null ||
                          (bookmark.tags != null &&
                            !bookmark.tags.some(
                              (s) =>
                                props.promptLabels != null &&
                                props.promptLabels.some((t) => t.id == s)
                            ))) && (
                          <>
                            <LabelItem
                              curserIsPointer={false}
                              hasCount={false}
                              name={"Undefined Label"}
                              color={"#b9b3b3"}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className={classes.AskBookmarkList_btn_Item}>
                      <div
                        style={{ cursor: "pointer", padding: "0px 5px" }}
                        title={"Edit"}
                        onClick={() => {
                          setBookMarkItem({
                            bookmarkId: bookmark.id,
                            text: bookmark.prompt_text,
                            bookmarkTitle: bookmark.prompt_title,
                            bookmarkTags: bookmark.tags,
                          });
                          setShowBookMarkBox(true);
                        }}
                      >
                        <EditIcon
                          title="Edit"
                          style={{
                            color: "rgba(38, 222, 115, 0.851)",
                            borderRadius: "50%",
                            width: "16px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <div
                        style={{ cursor: "pointer", padding: "0px 5px" }}
                        title={"Remove"}
                        onClick={() => {
                          callApiRemovePrompts(bookmark.id);
                        }}
                      >
                        <DeleteForeverIcon
                          style={{
                            color: "tomato",
                            width: "16px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </>
  );
}
export default PromptCards;

PromptCards.propTypes = {
  setPrompts: PropTypes.func,
  prompts: PropTypes.array,
  setPromptLabels: PropTypes.func,
  promptLabels: PropTypes.array,
  setFilterLabels: PropTypes.func,
  filterLabels: PropTypes.array,
  removeBookMarkFromHistory: PropTypes.func,
  AddBookMarkFromHistory: PropTypes.func,
};

PromptCards.defaultProps = {
  setPrompts: () => {},
  prompts: [],
  setPromptLabels: () => {},
  promptLabels: [],
  setFilterLabels: () => {},
  filterLabels: [],
  removeBookMarkinHistory: () => {},
  AddBookMarkFromHistory: PropTypes.func,
};
