import React, { useState } from "react";
import PropTypes from "prop-types";
import classes from "./PromptLabels.module.css";
import "../../../components/LiboBerryCss/liboBerry.css";
import LabelItem from "../../../components/Tags/LabelItem";
import AddTagDialog from "../../../Pages/DocumentsPage/components/CreateNewLabelDialog";
import {
  deletePromptsTags,
  getPromptsTags,
  postPromptsTags,
  editPromptsTags,
} from "../../../Requests/Requests";
import AddIcon from "@material-ui/icons/Add";
import { toast } from "react-toastify";
import { TextField, InputAdornment } from "@material-ui/core";
function PromptLabels(props) {
  const [searchLabels, setSearchLabels] = useState("");
  const [isAddTagDialogOpen, setIsAddTagDialogOpen] = useState(false);
  const [editLabel, setEditLabel] = useState(null);

  const countLabelUsege = (id) => {
    if (props.bookmarkList && props.bookmarkList.length > 0) {
      return props.bookmarkList.filter((BookmarkItem) => {
        if (id != -1) {
          if (BookmarkItem.tags != null && BookmarkItem.tags.length > 0) {
            return BookmarkItem.tags.some((s) => s == id);
          }
        } else {
          if (BookmarkItem.tags != null) {
            return (
              BookmarkItem.tags.length == 0 ||
              BookmarkItem.tags.filter(
                (s) =>
                  props.promptLabels != null &&
                  props.promptLabels.length > 0 &&
                  props.promptLabels.some((t) => s == t.id)
              ).length == 0
            );
          }
        }
        return false;
      }).length;
    }
    return 0;
  };
  const handleRemovePrompsTags = (id) => {
    deletePromptsTags(id)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          toast.success("Label has been deleted successfully");
          props.onDeleteClick(id);
          props.setPromptLabels([
            ...props.promptLabels.filter((s) => s.id != id),
          ]);
        }
      })
      .catch((err) => {
        if (err.response?.data?.error) {
          toast.error(err.response?.data?.error);
        } else if (
          err.response &&
          err.response.data &&
          err.response.data?.message
        ) {
          toast.error(err.response.data.message);
        } else if (
          err.response &&
          err.response.data &&
          err.response.data?.detail
        ) {
          toast.error(err.response.data.detail);
        } else {
          console.log("Seems like there is a problem with the server ", err);
          console.log("err.response", err.response);
          toast.error(
            "Ops! Seems like there is a problem with the server. Please check it."
          );
        }
      });
  };
  const callApiGetPrompts_tags = () => {
    getPromptsTags()
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          props.setPromptLabels(res.data);
        } else {
          props.setPromptLabels([]);
        }
      })
      .catch((err) => {
        console.log(
          "Seems like there is a problem with the server ",
          err.message
        );
      });
  };
  const handleAddPromps_tags = (data) => {
    if (editLabel == null) {
      return postPromptsTags(data);
    } else {
      let labelId = editLabel.id;
      return editPromptsTags(labelId, data);
    }
  };
  return (
    <>
      <div>
        <div>
          {props.hasCreate ? (
            <TextField
              placeholder="Search Labels"
              size="small"
              disabled={false}
              style={{ width: "100%" }}
              InputProps={{
                className: "inputFont inputFontSize0_9em",
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      title={"Create new label"}
                      className={
                        classes.AddLabelBox + " " + "defaultFontFamily"
                      }
                      onClick={(e) => {
                        setEditLabel(null);
                        setIsAddTagDialogOpen(true);
                      }}
                    >
                      Create label&nbsp;
                      <AddIcon
                        style={{
                          backgroundColor: "#ffffff",
                          borderRadius: "100%",
                          color: "#51cc84",
                          width: "16px",
                          height: "16px",
                        }}
                      />
                    </div>
                  </InputAdornment>
                ),
              }}
              value={searchLabels}
              onKeyDown={(e) => {
                if (e.which == 13) {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              onKeyUp={(e) => {
                if (e.which == 13) {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                setSearchLabels(e.target.value);
              }}
            ></TextField>
          ) : (
            <TextField
              placeholder="Search Labels"
              size="small"
              disabled={false}
              style={{ width: "100%" }}
              value={searchLabels}
              onKeyDown={(e) => {
                if (e.which == 13) {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              onKeyUp={(e) => {
                if (e.which == 13) {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                setSearchLabels(e.target.value);
              }}
            ></TextField>
          )}
        </div>
        <div
          className={props.hasScroll ? "liboScroll" : ""}
          style={{
            maxHeight: props.hasScroll ? props.scrollMaxHeight : null,
            overflowX: props.hasScroll ? "auto" : null,
          }}
        >
          {(searchLabels == null ||
            searchLabels?.trim() == "" ||
            "Undefined label"
              .toLowerCase()
              .trim()
              .includes(searchLabels?.toLowerCase().trim())) &&
            props.showUndifineLabel && (
              <LabelItem
                curserIsPointer={props.curserIsPointer}
                hasDeleteIcon={false}
                hasEditIcon={false}
                name={"Undefined label"}
                hasCount={props.hasCount}
                count={props.hasCount ? countLabelUsege(-1) : 0}
                color={"#b9b3b3"}
                isSelect={props.hasSelect ? props.isSelectLabel(-1) : false}
                handleFilterSelectLabel={() => {
                  if (props.hasSelect) {
                    props.handleSelectLabel(-1);
                  }
                }}
                type={props.typeLabelItem}
              />
            )}
          {props.promptLabels != null &&
            props.promptLabels.length != 0 &&
            props.promptLabels
              .filter((s) => {
                if (searchLabels == null || searchLabels.trim() == "") {
                  return true;
                }
                return s.tag_name
                  .toLowerCase()
                  .trim()
                  .includes(searchLabels?.toLowerCase().trim());
              })
              .sort((a, b) => (a.id > b.id ? 1 : a.id == b.id ? 0 : -1))
              .map((item, index) => {
                return (
                  <>
                    <LabelItem
                      curserIsPointer={props.curserIsPointer}
                      hasCount={props.hasCount}
                      hasEditIcon={props.hasEditIcon}
                      onEditClick={() => {
                        if (props.hasEditIcon) {
                          setEditLabel(item);
                          setIsAddTagDialogOpen(true);
                        }
                      }}
                      hasDeleteIcon={props.hasDeleteIcon}
                      onDeleteClick={() => {
                        if (props.hasDeleteIcon) {
                          handleRemovePrompsTags(item.id);
                        }
                      }}
                      key={item.id + "_" + index}
                      name={item.tag_name}
                      color={item.color}
                      type={props.typeLabelItem}
                      count={props.hasCount ? countLabelUsege(item.id) : 0}
                      isSelect={
                        props.hasSelect ? props.isSelectLabel(item.id) : false
                      }
                      handleFilterSelectLabel={() => {
                        if (props.hasSelect) {
                          props.handleSelectLabel(item.id);
                        }
                      }}
                    />
                  </>
                );
              })}
        </div>
      </div>
      <AddTagDialog
        title={(editLabel == null ? "Create New" : "Edit") + " Label"}
        open={isAddTagDialogOpen}
        onClose={() => {
          setEditLabel(null);
          setIsAddTagDialogOpen(false);
        }}
        onCreateTag={(id, name, color, mode) => {
          const isEditMode = editLabel == null ? "create" : "edit";
          setEditLabel(null);
          setIsAddTagDialogOpen(false);
          props.onSaveClick(id, name, color, isEditMode);
        }}
        onrefreshtag={callApiGetPrompts_tags}
        apiCallSetLabel={handleAddPromps_tags}
        useTagNameProperty={true}
        successMessage={editLabel == null ? "Create" : "Updated"}
        editLabel={
          editLabel == null
            ? null
            : {
                id: editLabel.id,
                name: editLabel.tag_name,
                color: editLabel.color,
              }
        }
        // onAddTag={handleAddTag} // pass the handleAddTag function
      />
    </>
  );
}
export default PromptLabels;
PromptLabels.propTypes = {
  setPromptLabels: PropTypes.func,
  promptLabels: PropTypes.array,
  showUndifineLabel: PropTypes.bool,
  curserIsPointer: PropTypes.bool,
  hasDeleteIcon: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  hasCount: PropTypes.bool,
  hasSelect: PropTypes.bool,
  isSelectLabel: PropTypes.func,
  handleSelectLabel: PropTypes.func,
  hasEditIcon: PropTypes.bool,
  onSaveClick: PropTypes.func,
  typeLabelItem: PropTypes.string,
  hasScroll: PropTypes.bool,
  scrollMaxHeight: PropTypes.string,
  bookmarkList: PropTypes.array,
  hasCreate: false,
};

PromptLabels.defaultProps = {
  setPromptLabels: () => {},
  promptLabels: [],
  showUndifineLabel: false,
  curserIsPointer: false,
  hasDeleteIcon: true,
  onDeleteClick: () => {},
  hasCount: false,
  hasSelect: false,
  isSelectLabel: () => {
    return false;
  },
  handleSelectLabel: () => {},
  hasEditIcon: true,
  onSaveClick: () => {},
  typeLabelItem: "clip",
  hasScroll: false,
  scrollMaxHeight: "100px",
  bookmarkList: [],
  hasCreate: true,
};
