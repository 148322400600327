import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, IconButton } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { toast } from "react-toastify";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Link } from "react-router-dom";
import { updateProject } from "../Requests/Requests.js";
import Authors from "./component/Authors/Authors.js";
import authorIcon from "./Images/authors.svg";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ImagesIcon from "./Images/images.svg";
import TitleIcon from "./Images/project.png";
import ProjectsInfo from "./component/ProjectsInfo/ProjectsInfo.js";
import Projects from "./component/Projects/Projects.js";
import PromptCards from "./component/PromptCards/PromptCards.js";
import PromptLabels from "./component/PromptLabels/PromptLabels.js";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { ImageDocumentUploadFileAllowMimeType } from "../Functions/FilterUploadFile.js";
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  chip: {
    margin: 5,
    backgroundColor: "#00800042",
    maxWidth: "100%",
  },
  errorChip: {
    margin: 5,
    backgroundColor: "#f50606bf",
    maxWidth: "100%",
  },
}));
function FloatDeleteButton(props) {
  return (
    <IconButton
      onClick={props.onClick}
      style={{
        backgroundColor: "white",
        margin: 0,
        padding: 0,
        color: "#EB3449",
        position: "absolute",
        top: -10,
        right: -10,
      }}
    >
      <HighlightOffIcon />
    </IconButton>
  );
}

FloatDeleteButton.propTypes = {
  onClick: PropTypes.func,
};
FloatDeleteButton.defaultProps = {
  onClick: () => {},
};

function Settings(props) {
  const classes = useStyles();
  const [selectedImages, setSelectedImages] = useState([]);

  const resetSelectedFiles = () => {
    setSelectedImages([]);
  };

  const handleDeleteSelectedFile = (index) => {
    setSelectedImages((chips) => chips.filter((chip, idx) => idx !== index));
  };

  const handleFileSelect = (e) => {
    let list = [...e.target.files].filter((item) =>
      ImageDocumentUploadFileAllowMimeType(item.type)
    );
    setSelectedImages((selectedImages) => [...selectedImages, ...list]);
  };
  const insertImage = (id, url) => {
    if (props.onPositionImageValue == null) {
      toast.error("Specify the location of the Image");
      return;
    }
    if (
      props.onPositionImageValue.startContainer.parentNode.tagName == "REF" ||
      props.onPositionImageValue.startContainer.parentNode.tagName ==
        "REFERENCES"
    ) {
      toast.error("Can not insert Image in to References");
      return;
    }
    let divElment = props.onPositionImageValue.startContainer.parentNode;
    while (
      divElment != null &&
      divElment.tagName != "DIV" &&
      divElment.tagName == "LIBOMATH" &&
      divElment.tagName != "ARTICLE"
    ) {
      divElment = divElment.parentNode;
    }
    if (divElment.tagName == "LIBOMATH") {
      toast.error("can not insert image to formula");
      return;
    }
    if (
      divElment != null &&
      divElment.tagName == "DIV" &&
      divElment.hasAttribute("caption")
    ) {
      toast.error("Can not insert Image in to Image Block");
      return;
    }
    if (
      divElment != null &&
      divElment.tagName == "DIV" &&
      divElment.hasAttribute("tablecaption")
    ) {
      toast.error("Can not insert Image in to Table Block");
      return;
    }
    try {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(props.onPositionImageValue);
    } catch (ex) {}

    document.execCommand(
      "insertHTML",
      false,
      '<div caption="true" style="text-align:center"><imgBlock><img scale="0.5" style="max-width:50%" loading="lazy" title="" src="' +
        url +
        '"/></imgBlock><imgFigure class="Figure_' +
        id +
        '">Figure-{number} </imgFigure><imgcaption>Image Caption</imgcaption> </div><div></br></div>'
    );
    toast.success("Image inserted!");
    props.onOrderByFigures();
    props.onPositionImage(null);
  };

  return (
    <div
      style={{
        width: 308,
        margin: "-10px -24px",
        ...(props.wrapperStyle ? props.wrapperStyle : {}),
      }}
    >
      <Link
        onClick={() => {
          props.onChange(props.projectInfoValue?.citation_style);
        }}
        to={`/panel/projects/${props.projectId}/manageReferences`}
        style={{ textDecoration: "none" }}
      >
        <Button
          style={{
            color: "black",
            textTransform: "none",
            backgroundColor: "#DDD",
            width: "calc(100% - 20px)",
            margin: "20px 10px 10px 10px",
          }}
        >
          Knowledge Management
        </Button>
      </Link>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <div style={{ display: "flex" }}>
              <img
                loading="lazy"
                style={{ width: "24px", marginTop: "-5px" }}
                src={ImagesIcon}
              />
              &nbsp; Project's Media
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          <div style={{ display: "flex" }}>
            <Button
              variant="contained"
              component="label"
              style={{ textTransform: "none", margin: 5 }}
            >
              Select Files(s)
              <input
                type="file"
                accept="image/*"
                hidden
                multiple
                onChange={handleFileSelect}
              />
            </Button>
            {selectedImages.length !== 0 && (
              <Button
                variant="contained"
                onClick={() =>
                  props.onFilesUploadButtonClick(
                    selectedImages,
                    resetSelectedFiles
                  )
                }
                style={{
                  margin: 5,
                  flex: 1,
                  backgroundColor: "green",
                  color: "white",
                }}
              >
                Upload
              </Button>
            )}
          </div>
          {selectedImages.length !== 0 && (
            <>
              Selected File(s):
              <br />
            </>
          )}

          {selectedImages.map((item, index) => {
            return (
              <Chip
                key={index}
                title={
                  item.name.length >= 100
                    ? "Upload failed! The file name exceed than 100 characters. Please rename the file."
                    : ""
                }
                icon={null}
                label={item.name}
                onDelete={() => handleDeleteSelectedFile(index)}
                className={
                  item.name.length >= 100 ? classes.errorChip : classes.chip
                }
              />
            );
          })}
          {selectedImages.length === 0 && "No Selected Files..."}
          <br />
          <br />
          {props.projectFiles.length !== 0 && (
            <>
              Project's Files:
              <br />
            </>
          )}
          {props.projectFiles.length === 0 && "No images added..."}
          {props.projectFiles.map((img) => (
            <div
              // draggable="true"
              key={img.id}
              title={img.name + "\nClick to copy the image link!"}
              style={{
                display: "inline-block",
                position: "relative",
                width: 70,
                height: 70,
                backgroundImage: `url(${img.file})`,
                backgroundSize: "cover",
                borderRadius: 5,
                boxShadow: "0 0 3px 0 #00000099",
                margin: 5,
                cursor: "pointer",
                backgroundPosition: "center",
              }}
              // onDragStart={(e) => {
              //     console.log('onDragStart', img.file)
              //     e.target.style.opacity = '0.4';
              //     e.effectAllowed = 'move';
              //     e.dataTransfer.setData('text', img.file);

              // }}
              // onDragEnd={(e) => {
              //     e.target.style.opacity = '1';
              // }}
              onClick={() => {
                // window.navigator.clipboard.writeText(img.file);
                // toast.success('Image link copied.')
                if (props.editorType == "html") {
                  insertImage(img.id, img.file);
                }
                if (props.editorType == "latex") {
                  props.onAddImage(img.id, img.file);
                }
              }}
            >
              <FloatDeleteButton
                onClick={(e) => {
                  e.stopPropagation();
                  props.onProjectFileDelete(img.id);
                }}
              />
            </div>
          ))}
        </AccordionDetails>
      </Accordion>

      {props.projectInfoValue != null ? (
        <>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                <div style={{ display: "flex" }}>
                  <img
                    loading="lazy"
                    style={{ height: "24px", marginTop: "-2px" }}
                    src={TitleIcon}
                  />
                  &nbsp; Project's Info
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "block" }}>
              <ProjectsInfo
                projectInfoValue={props.projectInfoValue}
                projectid={props.projectInfoValue?.id}
                setProjectInfo={props.setProjectInfo}
                onCitationTypeChange={props.onChange}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                <div style={{ display: "flex" }}>
                  <img
                    loading="lazy"
                    style={{ width: "24px", marginTop: "-8px" }}
                    src={authorIcon}
                  />
                  &nbsp; Project Author(s)
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "block" }}>
              <Authors
                AuthorsList={props.projectInfoValue?.authors}
                projectid={props.projectInfoValue?.id}
                setProjectInfo={(authors) => {
                  props.setProjectInfo({
                    ...props.projectInfoValue,
                    authors: authors,
                  });
                }}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                <div style={{ display: "flex" }}>
                  <InsertDriveFileIcon style={{ width: "24px" }} />
                  &nbsp; Your projects
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "block" }}>
              <Projects
                projectid={props.projectInfoValue?.id}
                onSave={props.onSave}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                <div style={{ display: "flex" }}>
                  <BookmarksIcon style={{ width: "24px" }} />
                  &nbsp; Prompt Cards
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "block" }}>
              <PromptCards
                prompts={props.prompts}
                setPrompts={props.setPrompts}
                setPromptLabels={props.setPromptLabels}
                promptLabels={props.promptLabels}
                filterLabels={props.bookmarkLabelsFilter}
                setFilterLabels={props.setBookmarkLabelsFilter}
                AddBookMarkFromHistory={props.AddBookMarkFromHistory}
                removeBookMarkFromHistory={props.removeBookMarkFromHistory}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginBottom: "40px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                <div style={{ display: "flex" }}>
                  <LocalOfferIcon style={{ width: "24px" }} />
                  &nbsp; Prompt Labels
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "block" }}>
              <PromptLabels
                setPromptLabels={props.setPromptLabels}
                promptLabels={props.promptLabels}
                hasScroll={false}
                scrollMaxHeight={"100px"}
                showUndifineLabel={true}
                curserIsPointer={false}
                hasDeleteIcon={true}
                onDeleteClick={(labelId) => {}}
                hasCount={true}
                bookmarkList={props.prompts}
                hasSelect={false}
                isSelectLabel={(labelId) => {
                  return false;
                }}
                handleSelectLabel={(labelId) => {}}
                hasEditIcon={true}
                onSaveClick={() => {}}
                typeLabelItem={"list"}
              />
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Settings;

Settings.propTypes = {
  onFilesUploadButtonClick: PropTypes.func,
  onArticlesUploadButtonClick: PropTypes.func,
  onProjectFileDelete: PropTypes.func,
  onProjectArticleDelete: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  projectFiles: PropTypes.array,
  articles: PropTypes.array,
  setProjectInfo: PropTypes.func,
  updateProject: PropTypes.func,
  onChange: PropTypes.func,
  onPositionImage: PropTypes.func,
  onOrderByFigures: PropTypes.func,
  ExportLiboberryGenerator: PropTypes.func,
  editorType: PropTypes.string,
  onAddImage: PropTypes.func,
  setPrompts: PropTypes.func,
  prompts: PropTypes.array,
  setPromptLabels: PropTypes.func,
  promptLabels: PropTypes.array,
  bookmarkLabelsFilter: PropTypes.array,
  setBookmarkLabelsFilter: PropTypes.func,
  AddBookMarkFromHistory: PropTypes.func,
  removeBookMarkFromHistory: PropTypes.func,
};

Settings.defaultProps = {
  articles: [],
  projectFiles: [],
  onFilesUploadButtonClick: () => {},
  onArticlesUploadButtonClick: () => {},
  onProjectFileDelete: () => {},
  onProjectArticleDelete: () => {},
  projectInfoValue: null,
  setProjectInfo: null,
  updateProject: () => {},
  onChange: null,
  onPositionImage: () => {},
  onPositionImageValue: null,
  onOrderByFigures: () => {},
  onSave: () => {},
  editorType: "html",
  onAddImage: () => {},
  setPrompts: () => {},
  prompts: [],
  setPromptLabels: () => {},
  promptLabels: [],
  bookmarkLabelsFilter: [],
  setBookmarkLabelsFilter: () => {},
  AddBookMarkFromHistory: () => {},
  removeBookMarkFromHistory: () => {},
};
