import axios from "axios";
import getToken from "../config/getToken";
import { setupInterceptors } from "./setupInterceptors";
import {
  aiURLS,
  authURLs,
  citationURLs,
  editorURLs,
  subscriptionURLs,
} from "./URLs";
const token = getToken();
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "X-CSRFToken": token,
  },
});

const axiosInstanceWithoutInterceptor = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "X-CSRFToken": token,
  },
});

export { axiosInstance, axiosInstanceWithoutInterceptor };

setupInterceptors();

axiosInstance.interceptors.request.use(
  function (config) {
    config.headers["X-CSRFToken"] = getToken();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/**
 * @param data {{old_password: string, new_password: string}}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const changePassword = (data) => {
  const url = authURLs.changePassword;
  return axiosInstance.post(url, data);
};
export const GetApiKeys = () => {
  const url = authURLs.get_api_keys;
  return axiosInstance.get(url);
};
export const GenerateApiKeys = (data) => {
  const url = authURLs.gen_api_keys;
  return axiosInstance.post(url, data);
};

/**
 * @param authData {{password: string, username: string}}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const login = (authData) => {
  const url = authURLs.login;
  return axiosInstance.post(url, authData);
};
export const requestPasswordReset = (authData) => {
  const url = authURLs.requestPasswordReset;
  return axiosInstance.post(url, authData);
};
export const resetPassword = (authData, token) => {
  const url = authURLs.resetPassword + token + "/";
  return axiosInstance.post(url, authData);
};
/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const logout = () => {
  const url = authURLs.logout;
  return axiosInstance.post(url);
};

/**
 * @param authData {{password: string, username: string}}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const signUp = (authData) => {
  const url = authURLs.signUp;
  return axiosInstance.post(url, authData);
};
export const saveUserInfo = (authData) => {
  const url = authURLs.userInfo;
  return axiosInstance.patch(url, authData);
};
export const uploadProfileImage = (data) => {
  const url = authURLs.userInfo;
  return axiosInstance.patch(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getUserInfo = (customAxiosInstance) => {
  const url = authURLs.userInfo;
  return (customAxiosInstance || axiosInstance).get(url);
};

export const authCheck = () => {
  const url = authURLs.userInfo;
  return new Promise((resolve, reject) => {
    // if (getToken()) return resolve(); // if just want to check the auth online, Rmove this line.
    axiosInstanceWithoutInterceptor.get(url).then(resolve).catch(reject);
  });
};

/**
 * @param userInfo {{first_name: string, last_name: string, profile_image: string}}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateUserInfo = (userInfo) => {
  const url = authURLs.userInfo;
  return axiosInstance.put(url, userInfo);
};

/**
 * @param projectId {number}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getProjectFiles = (projectId) => {
  const url = editorURLs.specialProjectFiles(projectId);
  return axiosInstance.get(url);
};
export const exportProject = (projectId, exportInfo) => {
  const url = editorURLs.exportProject(projectId);
  return axiosInstance.post(url, exportInfo, { responseType: "blob" });
};
export const SendFeedback = (data) => {
  const url = editorURLs.sendFeedback;
  return axiosInstance.post(url, data);
};
export const DuplicateProject = (duplicateInfo) => {
  const url = editorURLs.duplicateProject;
  return axiosInstance.post(url, duplicateInfo);
};

export const getParaphrase = (projectId, data) => {
  const url = aiURLS.paraphrase(projectId);
  return axiosInstance.post(url, data);
};
export const AddDocument_tags = (projectId, data) => {
  const url = aiURLS.documentTags(projectId);
  return axiosInstance.post(url, data);
};
export const getDocument_tags = (projectId) => {
  const url = aiURLS.documentTags(projectId);
  return axiosInstance.get(url);
};
export const deleteDocument_tags = (projectId, tag_id) => {
  const url = aiURLS.delete_DocumentTags(projectId, tag_id);
  return axiosInstance.delete(url);
};

export const postPrompts = (data) => {
  const url = editorURLs.prompts;
  return axiosInstance.post(url, data);
};
export const editPrompts = (tag_id, data) => {
  const url = editorURLs.delete_edit_prompts(tag_id);
  return axiosInstance.patch(url, data);
};
export const getPrompts = () => {
  const url = editorURLs.prompts;
  return axiosInstance.get(url);
};
export const deletePrompts = (tag_id) => {
  const url = editorURLs.delete_edit_prompts(tag_id);
  return axiosInstance.delete(url);
};

export const postPromptsTags = (data) => {
  const url = editorURLs.promptTag;
  return axiosInstance.post(url, data);
};
export const editPromptsTags = (tagid, data) => {
  const url = editorURLs.delete_edit_promptTags(tagid);
  return axiosInstance.patch(url, data);
};

export const getPromptsTags = () => {
  const url = editorURLs.promptTag;
  return axiosInstance.get(url);
};
export const deletePromptsTags = (tag_id) => {
  const url = editorURLs.delete_edit_promptTags(tag_id);
  return axiosInstance.delete(url);
};

export const getLabel = () => {
  const url = editorURLs.label;
  return axiosInstance.get(url);
};

export const setLabel = (data) => {
  const url = editorURLs.label;
  return axiosInstance.post(url, data);
};

export const deleteLabel = (id) => {
  const url = editorURLs.labeldelete(id);
  return axiosInstance.delete(url);
};
export const deleteLabels = (projectId, id) => {
  const url = editorURLs.labeldelete(projectId, id);
  return axiosInstance.delete(url);
};

export const projectShareList = (projectId) => {
  const url = editorURLs.shareList(projectId);
  return axiosInstance.get(url);
};
export const getShareConfirm = (project_Id, token) => {
  console.log(project_Id, token);
  const url = editorURLs.shareConfirm(project_Id, token);
  console.log(url);
  return axiosInstance.get(url);
};

export const projectShareDelete = (projectId, data) => {
  const url = editorURLs.shareDelete(projectId);
  return axiosInstance.post(url, data);
};

export const projectShare = (projectId, data) => {
  const url = editorURLs.share(projectId);
  return axiosInstance.post(url, data);
};

export const getAutoComplete = (projectId, data) => {
  const url = aiURLS.auto_complete(projectId);
  return axiosInstance.post(url, data);
};

/**
 * @param projectId
 * @param file
 * @returns {Promise<AxiosResponse<any>>}
 */
export const uploadProjectFile = (projectId, file) => {
  const url = editorURLs.specialProjectFiles(projectId);
  const f = new FormData();
  f.append("file", file);
  return axiosInstance.post(url, f, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @param projectId
 * @param file
 * @returns {Promise<AxiosResponse<any>>}
 */
export const uploadProjectFileCopy = (projectId, file, filename) => {
  const url = editorURLs.specialProjectFiles(projectId);
  const f = new FormData();
  f.append("file", file, filename);
  return axiosInstance.post(url, f, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @param projectId {number}
 * @param fileId {number}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteProjectFile = (projectId, fileId) => {
  const url = editorURLs.specialProjectFile(projectId, fileId);
  return axiosInstance.delete(url);
};

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getProjects = () => {
  const url = editorURLs.projects;
  return axiosInstance.get(url);
};

/**
 * @param projectInfo {{title: string, pined: boolean, type: string}}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createProject = (projectInfo) => {
  const url = editorURLs.projects;
  return axiosInstance.post(url, projectInfo);
};

/**
 * @param projectId {number}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getProject = (projectId) => {
  const url = editorURLs.specialProject(projectId);
  return axiosInstance.get(url);
};

/**
 * @param projectId {number}
 * @param projectInfo {{title: string, pined: boolean, type: string, content}}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateProject = (projectId, projectInfo) => {
  const url = editorURLs.specialProject(projectId);
  return axiosInstance.patch(url, projectInfo);
};
export const getDocumentCitations = (projectId, Parametrs) => {
  const url = aiURLS.document_citations(projectId);
  return axiosInstance.post(url, Parametrs);
};
export const getXref = (projectId, Parametrs) => {
  const url = citationURLs.xref(projectId);
  return axiosInstance.post(url, Parametrs);
};
export const saveXref = (projectId, Parametrs) => {
  const url = citationURLs.saveXref(projectId);
  return axiosInstance.post(url, Parametrs);
};

/**
 * @param projectId {number}
 * @param docId {number}
 * @param documentData {{documentbibtexdata: object}}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateProjectDocument = (projectId, docId, documentData) => {
  const url = aiURLS.specialDocument(projectId, docId);
  return axiosInstance.patch(url, documentData);
};
export const updateProjectDocumentFile = (projectId, docId, document) => {
  const url = aiURLS.specialDocument(projectId, docId);
  const f = new FormData();
  f.append("file", document);
  f.append("active", true);
  return axiosInstance.patch(url, f, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteDocumentFileOfProject = (projectId, doc_id) => {
  const url = aiURLS.deleteDocumentFile(projectId, doc_id);
  return axiosInstance.patch(url);
};

/**
 * @param projectId {number}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteProject = (projectId) => {
  const url = editorURLs.specialProject(projectId);
  return axiosInstance.delete(url);
};

/**
 * @param projectId {number}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getProjectDocuments = (projectId) => {
  const url = aiURLS.documents(projectId);
  return axiosInstance.get(url);
};

/**
 * @param projectId {number}
 * @param documentId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteProjectDocument = (projectId, documentId) => {
  const url = aiURLS.specialDocument(projectId, documentId);
  return axiosInstance.delete(url);
};

/**
 * @param projectId {number}
 * @param document
 * @returns {Promise<AxiosResponse<any>>}
 */
export const uploadProjectDocument = (projectId, document) => {
  const url = aiURLS.documents(projectId);
  const f = new FormData();
  f.append("file", document);
  f.append("active", true);
  return axiosInstance.post(url, f, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const uploadProjectDocumentWithURL = (projectId, fileUrl) => {
  const url = aiURLS.documents(projectId);
  const f = new FormData();
  f.append("url", fileUrl);
  f.append("active", true);
  return axiosInstance.post(url, f, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const CreateProjectDocumentWithDocumentbibtexdata = (
  projectId,
  documentbibtexdata
) => {
  const url = aiURLS.documents(projectId);
  const f = new FormData();
  f.append("documentbibtexdata", JSON.stringify(documentbibtexdata));
  f.append("active", true);
  return axiosInstance.post(url, f, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const CreateProjectDocumentWithBibTex = (projectId, bibtex_content) => {
  const url = aiURLS.documents(projectId);
  const f = new FormData();
  f.append("bibtex_content", bibtex_content);
  f.append("active", true);
  return axiosInstance.post(url, f, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
// -------------------------------------- AI Model--------------------------------------//

/**
 * @param projectId {number}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getProjectMLModels = (projectId) => {
  const url = aiURLS.mlModels(projectId);
  return axiosInstance.get(url);
};

/**
 * @param projectId {number}
 * @param modelId {number}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getMLModel = (projectId, modelId) => {
  const url = aiURLS.specificMlModel(projectId);
  return axiosInstance.get(url);
};

/**
 * @param projectId {number}
 * @param modelData {object}
 * @returns {Promise<AxiosResponse<any>>}
 */
// export const createMLModel = (projectId, modelData) => {
//     const url = `${aiURLS.projects}/${projectId}/ml_models`;
//     return axiosInstance.post(url, modelData);
// };

/**
 * @param projectId {number}
 * @param modelId {number}
 * @param modelData {object}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateMLModel = (projectId, modelId, modelData) => {
  const url = aiURLS.specificMlModel(projectId, modelId);
  return axiosInstance.patch(url, modelData);
};
export const generateFormula = (projectId, data) => {
  const url = aiURLS.GnteFormula(projectId);

  return axiosInstance.post(url, data);
};
/**
 * @param projectId {number}
 * @param modelId {number}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteMLModel = (projectId, modelId) => {
  const url = aiURLS.specificMlModel(projectId, modelId);
  return axiosInstance.delete(url);
};

// -------------------------------------- subscription --------------------------------------

export const api = {
  getLiboberryPlans: () => {
    const url = subscriptionURLs.liboPlans;
    return axiosInstance.get(url);
  },
  getPubberryPackages: () => {
    const url = subscriptionURLs.pubPackages;
    return axiosInstance.get(url);
  },
  getRefberryPackages: () => {
    const url = subscriptionURLs.refPackages;
    return axiosInstance.get(url);
  },
  getPaypalClientToken: () => {
    const url = subscriptionURLs.paypalClientToken;
    return axiosInstance.get(url);
  },
  /**
   * @param {{plan_id: any, payment_method_nonce: string}} data
   * @returns
   */
  buySubscription: ({ plan_id, payment_method_nonce }) => {
    const url = subscriptionURLs.subscription;
    return axiosInstance.post(url, { plan_id, payment_method_nonce });
  },
  /**
   * @param {{credit_package_id: any, payment_method_nonce: string}} data
   * @returns
   */
  buyPubberryCredit: ({ credit_package_id, payment_method_nonce }) => {
    const url = subscriptionURLs.buyPub;
    return axiosInstance.post(url, {
      credit_package_id,
      payment_method_nonce,
    });
  },
  /**
   * @param {{credit_package_id: any, payment_method_nonce: string}} data
   * @returns
   */
  buyRefberryCredit: ({ credit_package_id, payment_method_nonce }) => {
    const url = subscriptionURLs.buyRef;
    return axiosInstance.post(url, {
      credit_package_id,
      payment_method_nonce,
    });
  },
};
