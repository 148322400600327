import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../../../components/LiboBerryCss/liboBerry.css";
import LabelItem from "../../../components/Tags/LabelItem";
import { Button, FormControl, TextField, FormLabel } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import { postPrompts, editPrompts } from "../../../Requests/Requests";
import { CircularProgress } from "@material-ui/core";
import PromptLabels from "../../../Containers/component/PromptLabels/PromptLabels";
function BookMarkBox(props) {
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [loadingPrompt, setLoadingPrompt] = useState(false);

  useEffect(() => {
    setText(props.isShow ? props.promptText : "");
    setTitle(props.isShow ? props.promptTitle : "");
    setLoadingPrompt(false);

    if (props.isShow) {
      setTags([...props.PromptTags]);
    } else {
      setTags([]);
    }
  }, [props.isShow]);

  const handleAddTagClick = (tagId, mode = "create") => {
    if (mode != "edit") {
      if (tags == null || tags.length == 0) {
        setTags([tagId]);
      } else if (tags != null && tags.length > 0) {
        if (tags.filter((s) => s == tagId).length == 0) {
          setTags([...tags, tagId]);
        }
      }
    }
  };
  const handleRemoveTag = (tagId) => {
    if (tags.filter((s) => s == tagId).length > 0) {
      setTags([...tags.filter((s) => s != tagId)]);
    }
  };

  const callApiEditPrompt = () => {
    setLoadingPrompt(true);
    let labels = [
      ...tags.filter(
        (s) =>
          props.promptLabels != null &&
          props.promptLabels.filter((t) => t.id == s).length > 0
      ),
    ];
    editPrompts(props.promptId, {
      prompt_text: text.trim(),
      prompt_title: title.trim(),
      tags: labels,
    })
      .then((res) => {
        setLoadingPrompt(false);
        if (res.status >= 200 && res.status <= 299) {
          toast.success("Prompt has been updated successfully");
          props.Save({
            bookmarkId: props.promptId,
            bookmarkTitle: title.trim(),
            bookmarkTags: labels,
            bookmarkText: text.trim(),
          });
        }
      })
      .catch((err) => {
        setLoadingPrompt(false);
        if (err.response?.data?.error) {
          toast.error(err.response?.data?.error);
        } else if (
          err.response &&
          err.response.data &&
          err.response.data?.message
        ) {
          toast.error(err.response.data.message);
        } else if (
          err.response &&
          err.response.data &&
          err.response.data?.detail
        ) {
          toast.error(err.response.data.detail);
        } else {
          console.log("Seems like there is a problem with the server ", err);
          console.log("err.response", err.response);
          toast.error(
            "Ops! Seems like there is a problem with the server. Please check it."
          );
        }
      });
  };
  const callApiAddPrompt = () => {
    setLoadingPrompt(true);
    postPrompts({
      prompt_text: text.trim(),
      prompt_title: title.trim(),
      tags: tags,
    })
      .then((res) => {
        setLoadingPrompt(false);
        if (res.status >= 200 && res.status <= 299) {
          toast.success("Prompt has been saved successfully");
          props.Save({
            bookmarkId: res.data.id,
            bookmarkTitle: title.trim(),
            bookmarkTags: tags,
            bookmarkText: text.trim(),
          });
        }
      })
      .catch((err) => {
        setLoadingPrompt(false);
        if (err.response?.data?.error) {
          toast.error(err.response?.data?.error);
        } else if (
          err.response &&
          err.response.data &&
          err.response.data?.message
        ) {
          toast.error(err.response.data.message);
        } else if (
          err.response &&
          err.response.data &&
          err.response.data?.detail
        ) {
          toast.error(err.response.data.detail);
        } else {
          console.log("Seems like there is a problem with the server ", err);
          console.log("err.response", err.response);
          toast.error(
            "Ops! Seems like there is a problem with the server. Please check it."
          );
        }
      });
  };
  const checkValidation = () => {
    let hasError = false;
    if (loadingPrompt) {
      return false;
    } else {
      if (title == null || title.trim() == "") {
        toast.error("Please input title");
        hasError = true;
      }
      if (text == null || text.trim() == "") {
        hasError = true;
        toast.error("Please input prompt");
      }
    }
    return !hasError;
  };
  return (
    <>
      {props.isShow && (
        <Dialog
          classes={{ paper: { background: "red" } }}
          open={props.isShow}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            style={{
              color: "white",
              backgroundColor: "#eb3449",
            }}
            id="alert-dialog-title"
          >
            {"Bookmark"}
          </DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              minWidth: "320px",
            }}
          >
            <TextField
              InputProps={{
                className: "inputFont",
              }}
              id="txtPromptTitle"
              label="Title"
              variant="outlined"
              style={{ marginTop: "30px" }}
              disabled={false}
              defaultValue={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              InputProps={{
                className: "inputFont",
              }}
              id="txtPromptText"
              label="Prompt"
              variant="outlined"
              style={{ marginTop: "30px" }}
              disabled={false}
              defaultValue={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
            <br />
            <FormControl
              component="fieldset"
              style={{
                padding: "5px 0px 5px 8px",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "5px",
              }}
            >
              <FormLabel
                component="legend"
                style={{
                  fontSize: "0.75em",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                Labels
              </FormLabel>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {tags &&
                  tags.length > 0 &&
                  tags.map((tag, index) => {
                    if (
                      props.promptLabels != null &&
                      props.promptLabels.length > 0
                    ) {
                      let labels = [
                        ...props.promptLabels.filter((s) => s.id == tag),
                      ];
                      if (labels.length > 0) {
                        let label = labels.pop();
                        return (
                          <LabelItem
                            curserIsPointer={false}
                            hasCount={false}
                            key={index + " " + tag}
                            name={label.tag_name}
                            color={label.color}
                            hasDeleteIcon={true}
                            onDeleteClick={() => {
                              handleRemoveTag(tag);
                            }}
                          />
                        );
                      }
                    }
                  })}
              </div>
              <PromptLabels
                setPromptLabels={props.setPromptLabels}
                promptLabels={props.promptLabels}
                hasScroll={true}
                scrollMaxHeight={"60px"}
                showUndifineLabel={false}
                curserIsPointer={true}
                hasDeleteIcon={true}
                onDeleteClick={(labelId) => {
                  handleRemoveTag(labelId);
                }}
                hasCount={true}
                hasSelect={true}
                isSelectLabel={(labelId) =>
                  tags.filter((s) => s == labelId).length > 0
                }
                handleSelectLabel={(labelId) => {
                  if (tags.filter((s) => s == labelId).length > 0) {
                    handleRemoveTag(labelId);
                  } else {
                    handleAddTagClick(labelId);
                  }
                }}
                bookmarkList={props.prompts}
                hasEditIcon={true}
                onSaveClick={(id, name, color, mode) => {
                  handleAddTagClick(id, mode);
                }}
                typeLabelItem={"list"}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                margin: "0px 15px",
              }}
            >
              <Button onClick={props.Close} className={"liboBtnCancel"}>
                Cancel
              </Button>

              <Button
                onClick={() => {
                  if (checkValidation()) {
                    if (props.promptId) {
                      callApiEditPrompt();
                    } else {
                      callApiAddPrompt();
                    }
                  }
                }}
                className={loadingPrompt ? "liboBtnDisable" : "liboBtnApprove"}
                autoFocus
              >
                {<>{props.promptId == null ? "Save" : "Update"}</>}
                {loadingPrompt && (
                  <>
                    &nbsp;&nbsp;
                    <CircularProgress
                      style={{
                        height: "22px",
                        width: "22px",
                      }}
                    ></CircularProgress>
                  </>
                )}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
BookMarkBox.propTypes = {
  isShow: PropTypes.bool,
  Close: PropTypes.func,
  promptText: PropTypes.string,
  promptId: PropTypes.number,
  promptTitle: PropTypes.string,
  PromptTags: PropTypes.array,
  Save: PropTypes.func,
  promptLabels: PropTypes.array,
  setPromptLabels: PropTypes.func,
  prompts: PropTypes.array,
};

BookMarkBox.defaultProps = {
  isShow: false,
  Close: () => {},
  promptText: "",
  promptId: null,
  promptTitle: "",
  PromptTags: [],
  Save: () => {},
  promptLabels: [],
  setPromptLabels: () => {},
  prompts: [],
};

export default BookMarkBox;
