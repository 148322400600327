import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Divider,
  IconButton,
  Checkbox,
  TextField,
} from "@material-ui/core";
import { makeStyles, CircularProgress } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { CloudDownload } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import {
  DownloadExportFile,
  findReferenceIds,
} from "../../Functions/liboFunction";
import ExtentionIcon from "../../components/ExtentionIcon/ExtentionIcon.js";
import {
  deleteProject,
  exportProject,
  DuplicateProject,
  getProjects,
  getProject,
  getLabel,
  deleteLabel,
  updateProject,
  projectShareList,
  setLabel,
} from "../../Requests/Requests";
import { toast } from "react-toastify";
import DeleteIcon from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import DocStyles from "./DocumentsPage.module.css";
import "./DocumentPage.css";
import "../../components/LiboBerryCss/liboBerry.css";
import "./components/CreateNewProjectButton.js";
import DuplicateProjectDialog from "./components/DuplicateProjectDialog.js";
import NewProjectDialog from "./components/NewProjectDialog.js";
import ShareIcon from "@material-ui/icons/Share";
import ShareProjectDialog from "./components/ShareProjectDialog";
import LabelIcon from "@material-ui/icons/Label";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Menu, MenuItem } from "@material-ui/core";
import AddTagDialog from "./components/CreateNewLabelDialog.js";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import ArchiveIcon from "@material-ui/icons/Archive";
import GetAppIcon from "@material-ui/icons/GetApp";
import { ReactComponent as Pin } from "../DocumentsPage/Images/pin.svg";
import { ReactComponent as UnPin } from "../DocumentsPage/Images/unpin.svg";
import Switch from "@material-ui/core/Switch";
import { Collapse } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import GroupIcon from "@material-ui/icons/Group";
import CopyIcon from "../../components/Icons/CopyIcon.js";
import FolderSharedIcon from "@material-ui/icons/FolderShared";
import LiboBerryFeatures from "./components/LiboBerryFeaturesAD.js";
import CreateNewProjectButton from "./components/CreateNewProjectButton.js";
const useStyles = makeStyles((theme) => ({
  searchInput: {
    "& .MuiInputBase-input": {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(0, 12px) scale(1)",
    },
  },
  page: {
    padding: 20,
  },

  flexContainer: {
    display: "flex",
    flexDirection: "column", // Ensure it stacks vertically
    alignItems: "center",
    padding: "20px", // Add padding if needed
  },
  flexContainer2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px", // Add padding if needed
  },
  sectionTitle: {
    fontSize: 20,
    color: "#444",
  },
  table: {
    minWidth: 650,
  },
  head: {
    background: "#f1eff1",
  },
  hoverableRow: {
    // cursor: 'pointer',
    "&:hover": {
      background: "#0000000F",
    },
  },
  pinnedRow: {
    background: "#f1eff1",
  },
}));

function DocumentsPage(props) {
  const classes = useStyles();
  const [newProjectModalIsOpen, setNewProjectModalIsOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);
  const [duplicateProjectName, setDuplicateProjectName] = useState("");
  const [duplicateProjectId, setDuplicateProjectId] = useState(null);
  const newProjectNameRef = useRef(null);
  const [sortFielddate, setSortFielddate] = useState("last_modified"); // Adjust to the correct field name
  const [sortOrderdate, setSortOrderdate] = useState("desc"); // or 'desc' for descending
  const [currentStep, setCurrentStep] = useState(1);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [selectProjectId, setSelectProjectId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsArchive, setSelectedRowsArchive] = useState([]);
  const [showTagIcon, setShowTagIcon] = useState(false);
  const [showTagIconArch, setShowTagIconArch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAddTagDialogOpen, setIsAddTagDialogOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchLabelInput, setSearchLabelInput] = useState("");
  const [filterSelectLabel, setFilterSelectLabel] = useState([]);
  const [downloadAllProject, setDownloadAllProject] = useState([]);
  const [deleteAllProjectRows, setDeleteAllProjectRows] = useState([]);
  const [pinnedProjects, setPinnedProjects] = useState([]);
  const [shareList, setShareList] = useState([]);
  const [isArchiveTableOpen, setIsArchiveTableOpen] = useState(true);

  const hasOwnerProject = () => {
    return (
      projects.filter(
        (s) =>
          s.is_owner == true &&
          (selectedRows.includes(s.id) || selectedRowsArchive.includes(s.id))
      ).length > 0 ||
      (selectedRows.length == 0 && selectedRowsArchive.length == 0)
    );
  };
  const toggleArchiveTable = () => {
    if (isArchiveTableOpen) {
      setIsArchiveTableOpen(false);
      setSelectedRowsArchive([]);
    } else {
      setIsArchiveTableOpen(true);
    }
  };

  const updateArchive = (projectId, archived) => {
    updateProject(projectId, { archived: archived })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          if (res.data.message) {
            toast.success(res.data.message);
          }
        }
      })
      .catch((err) => {
        if (err.response.data.error) {
          toast.error(err.response.data.error);
        }
      });
  };

  const handleArchiveSelectedProjects = (StatusArchived) => {
    const archived = projects
      .filter(
        (project) =>
          (selectedRows.includes(project.id) && StatusArchived == true) ||
          (selectedRowsArchive.includes(project.id) && StatusArchived == false)
      )
      .map((s) => {
        return s.id;
      });

    const updateProjects = projects.map((project) => {
      if (archived.includes(project.id)) {
        return { ...project, archived: StatusArchived };
      }
      return { ...project };
    });

    for (let item of archived) {
      updateArchive(item, StatusArchived);
    }
    setProjects(updateProjects);

    if (!StatusArchived) {
      setSelectedRowsArchive([]);
    }
    if (StatusArchived) {
      setSelectedRows([]);
    }
  };

  const updatePin = (projectId, pined) => {
    updateProject(projectId, { pined: pined })
      .then((res) => {})
      .catch((err) => {
        console.log(err, err.response);
      });
  };

  const handlePinProject = (statusPin) => {
    let list = [];
    if (statusPin == false) {
      list = [...selectedRows, ...selectedRowsArchive].filter((s) =>
        pinnedProjects.includes(s)
      );
      setPinnedProjects([...pinnedProjects.filter((id) => !list.includes(id))]);
    } else {
      list = [...selectedRows, ...selectedRowsArchive].filter(
        (s) => !pinnedProjects.includes(s)
      );
      setPinnedProjects([...pinnedProjects, ...list]);
    }

    for (let projectId of list) {
      updatePin(projectId, statusPin);
    }
  };

  const updateProjectLabel = (projectId, labels) => {
    updateProject(projectId, { labels: labels })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          if (res.data.message) {
            toast.success(res.data.message);
          }
        }
      })
      .catch((err) => {
        if (err.response.data.error) {
          toast.error(err.response.data.error);
        }
      });
  };

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const handleAddTag = (projectId, tagName, tagColor, tagId) => {
    const updatedProjects = projects.map((project) => {
      if (project.is_owner) {
        if (
          selectedRows.includes(project.id) ||
          selectedRowsArchive.includes(project.id)
        ) {
          if (project.labels && Array.isArray(project.labels)) {
            const existingTag = project.labels.find((tag) => tag.id == tagId);
            if (!existingTag) {
              const updatedTags = [
                ...project.labels,
                { name: tagName, color: tagColor, id: tagId },
              ];
              updateProjectLabel(projectId, updatedTags);
              return { ...project, labels: updatedTags };
            }
          } else {
            const updatedTags = [{ name: tagName, color: tagColor, id: tagId }];
            updateProjectLabel(projectId, updatedTags);
            return { ...project, labels: updatedTags };
          }
        }
      }
      return project;
    });
    setProjects(updatedProjects);
  };
  const handleRemoveTag = (tagId, removeAll = false) => {
    const updatedProjects = projects.map((project) => {
      if (project.is_owner) {
        if (
          (selectedRows.length > 0 && selectedRows.includes(project.id)) ||
          (selectedRowsArchive.length > 0 &&
            selectedRowsArchive.includes(project.id)) ||
          removeAll
        ) {
          if (project.labels && Array.isArray(project.labels)) {
            const existingTag = project.labels.find((tag) => tag.id == tagId);

            if (existingTag) {
              let filterlabels = project.labels.filter((s) => s.id != tagId);
              const updatedTags = [...filterlabels];
              updateProjectLabel(project.id, updatedTags);
              return { ...project, labels: updatedTags };
            }
          }
        }
      }
      return project;
    });
    setProjects(updatedProjects);
  };

  const isSelectedLabel = (labelId) => {
    let labelsStatus = [];
    let selectionRows = [...selectedRows, ...selectedRowsArchive];
    if (selectionRows.length > 0) {
      for (let project of projects) {
        if (project.is_owner) {
          if (selectionRows.includes(project.id)) {
            if (project.labels && Array.isArray(project.labels)) {
              labelsStatus.push(
                project.labels.findIndex((tag) => tag.id == labelId) >= 0
              );
            }
          }
        }
      }
    }

    let hasFalseLabel = labelsStatus.findIndex((s) => s == false) >= 0;
    let hasTrueLabel = labelsStatus.findIndex((s) => s == true) >= 0;

    if (hasTrueLabel && !hasFalseLabel) {
      return "true";
    } else if (hasTrueLabel && hasFalseLabel) {
      return "indeterminate";
    }
    return "false";
  };
  const handleAddTagClick = (tagName, tagColor, tagId) => {
    if (selectedRows.length > 0 || selectedRowsArchive.length > 0) {
      selectedRows.forEach((projectId) => {
        handleAddTag(projectId, tagName, tagColor, tagId);
      });
      selectedRowsArchive.forEach((projectId) => {
        handleAddTag(projectId, tagName, tagColor, tagId);
      });
    }
  };

  const handleRemoveTagClick = (tagId, removeAll = false) => {
    if (removeAll) {
      handleRemoveTag(tagId, removeAll);
    } else {
      handleRemoveTag(tagId);
    }
  };

  const callApiGetLabel = () => {
    getLabel()
      .then((res) => {
        setTags(res.data);
      })
      .catch((err) => {
        console.log(err, err.response);
      });
  };

  const handleDeleteTags = (id) => {
    const updatedTags = tags.filter((tag) => tag.id != id);

    setTags(updatedTags);

    deleteLabel(id)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          if (response.data.message) {
            toast.success(response.data.message);
          }
        }
      })
      .catch((error) => {
        if (error.response.data.error) {
          toast.error(error.response.data.error);
        }
      });
  };

  const handleDeleteTag = (projectId, tagId) => {
    const updatedProjects = projects.map((project) => {
      if (project.id === projectId) {
        const updatedTags = project.labels.filter((tag) => tag.id !== tagId);
        return { ...project, labels: updatedTags };
      }
      return project;
    });
    let pr = updatedProjects;
    setProjects(pr);
    let index = pr.findIndex((project) => project.id == projectId);
    if (index >= 0) {
      updateProject(projectId, { labels: pr[index].labels });
    }
  };

  // Function to handle opening and closing of the add tag dialog
  const handleOpenAddTagDialog = () => {
    setIsAddTagDialogOpen(true);
  };

  const handleCloseAddTagDialog = () => {
    setIsAddTagDialogOpen(false);
  };

  // Function to handle creation of new tag
  const handleCreateTag = (id, tagName, tagColor) => {
    handleAddTagClick(tagName, tagColor, id);
    setIsAddTagDialogOpen(false);
  };

  const handleOpenMenu = (event) => {
    if (hasOwnerProject()) {
      setSearchLabelInput("");
      if (Boolean(anchorEl)) {
        handleCloseMenu();
      } else {
        setAnchorEl(event.currentTarget);
      }
    } else {
      handleCloseMenu();
    }
  };

  const handleCloseMenu = () => {
    setSearchLabelInput("");
    setAnchorEl(null);
  };

  const handleOpenShareDialog = (id) => {
    setSelectProjectId(id);
    projectShareList(id)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          setShareList(res.data.shared_with);
        } else {
          setShareList([]);
        }
        setIsShareDialogOpen(true);
      })
      .catch((err) => {
        setShareList([]);
        setIsShareDialogOpen(true);
        toast.error(err.response.data.error);
      });
  };

  const handleCloseShareDialog = () => {
    setSelectProjectId(null);
    setIsShareDialogOpen(false);
  };

  const sortProjectsdate = (a, b) => {
    const aIsPinned = pinnedProjects.includes(a.id);
    const bIsPinned = pinnedProjects.includes(b.id);

    if (aIsPinned && !bIsPinned) return -1;
    if (!aIsPinned && bIsPinned) return 1;
    // If sorting by date
    if (sortFielddate === "last_modified") {
      const dateA = new Date(a[sortFielddate]);
      const dateB = new Date(b[sortFielddate]);
      return sortOrderdate === "asc" ? dateA - dateB : dateB - dateA;
    }

    if (a[sortFielddate] < b[sortFielddate]) {
      return sortOrderdate === "asc" ? -1 : 1;
    }
    if (a[sortFielddate] > b[sortFielddate]) {
      return sortOrderdate === "asc" ? 1 : -1;
    }
    return 0;
  };

  const handleSortChange = (field) => {
    if (sortFielddate === field) {
      // Toggle sort order if the same field is clicked again
      setSortOrderdate(sortOrderdate === "asc" ? "desc" : "asc");
    } else {
      // Change the sort field and set order to ascending
      setSortFielddate(field);
      setSortOrderdate("asc");
    }
  };

  const [sortFielddateArchive, setSortFielddateArchive] =
    useState("last_modified"); // Adjust to the correct field name
  const [sortOrderdateArchive, setSortOrderdateArchive] = useState("desc");

  const sortProjectsdateArchive = (a, b) => {
    const aIsPinned = pinnedProjects.includes(a.id);
    const bIsPinned = pinnedProjects.includes(b.id);

    if (aIsPinned && !bIsPinned) return -1;
    if (!aIsPinned && bIsPinned) return 1;
    // If sorting by date
    if (sortFielddateArchive === "last_modified") {
      const dateA = new Date(a[sortFielddateArchive]);
      const dateB = new Date(b[sortFielddateArchive]);
      return sortOrderdateArchive === "asc" ? dateA - dateB : dateB - dateA;
    }

    if (a[sortFielddateArchive] < b[sortFielddateArchive]) {
      return sortOrderdateArchive === "asc" ? -1 : 1;
    }
    if (a[sortFielddateArchive] > b[sortFielddateArchive]) {
      return sortOrderdateArchive === "asc" ? 1 : -1;
    }
    return 0;
  };

  const handleSortChangeArchive = (field) => {
    if (sortFielddateArchive === field) {
      // Toggle sort order if the same field is clicked again
      setSortOrderdateArchive(sortOrderdateArchive === "asc" ? "desc" : "asc");
    } else {
      // Change the sort field and set order to ascending
      setSortFielddateArchive(field);
      setSortOrderdateArchive("asc");
    }
  };

  // const sortProjects = (a, b) => {
  //   if (a[sortField] < b[sortField]) {
  //     return sortOrder === "asc" ? -1 : 1;
  //   }
  //   if (a[sortField] > b[sortField]) {
  //     return sortOrder === "asc" ? 1 : -1;
  //   }
  //   return 0;
  // };

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    let rowslist = searchProjects().map((s) => s.id);

    setSelectedRows(
      rowslist
        .filter((s) => s.archived == false)
        .filter((pr) => selectedRows.includes(pr.id))
    );
    setSelectedRowsArchive(
      rowslist
        .filter((s) => s.archived == true)
        .filter((pr) => selectedRowsArchive.includes(pr.id))
    );
  }, [searchInput, filterSelectLabel]);
  useEffect(() => {
    if (deleteAllProjectRows.length == 0) {
      refreshProjects();
    }
  }, [deleteAllProjectRows]);
  const handleClickOpen = () => {
    setNewProjectModalIsOpen(true);
    setTimeout(() => {
      if (isMounted && newProjectNameRef.current) {
        newProjectNameRef.current.focus();
      }
    }, 100);
  };
  const handleCloseNewProjectModal = () => {
    setNewProjectModalIsOpen(false);
    setCurrentStep(1);
  };
  const refreshProjects = () => {
    getProjects()
      .then((res) => {
        setProjects(res.data);
        setPinnedProjects(
          res.data.filter((s) => s.pined == true).map((s) => s.id)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDeleteAllProject = (projectId, title) => {
    deleteProject(projectId)
      .then((res) => {
        setDeleteAllProjectRows([
          ...deleteAllProjectRows.filter((s) => s != projectId),
        ]);
        setSelectedRows([...selectedRows.filter((s) => s != projectId)]);
        setSelectedRowsArchive([
          ...selectedRowsArchive.filter((s) => s != projectId),
        ]);

        if (res.status >= 200 && res.status <= 299) {
          toast.success("Project " + title + " deleted!");
        }
      })
      .catch((err) => {
        setDeleteAllProjectRows([
          ...deleteAllProjectRows.filter((s) => s != projectId),
        ]);
        setSelectedRows([...selectedRows.filter((s) => s != projectId)]);
        setSelectedRowsArchive([
          ...selectedRowsArchive.filter((s) => s != projectId),
        ]);
        if (err.response.data.error) {
          toast.error(err.response.data.error);
        } else {
          console.log(err, err.response);
          toast.error("An error occurred!");
        }
      });
  };
  const handleDeleteProject = (id) => {
    deleteProject(id)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          toast.success("Project deleted!");
          refreshProjects();
        }
      })
      .catch((err) => {
        if (err.response.data.error) {
          toast.error(err.response.data.error);
        } else {
          console.log(err, err.response);
          toast.error("An error occurred!");
        }
      });
  };

  const handleDuplicateProject = (project) => {
    setDuplicateProjectId(project.id);
    setDuplicateProjectName(`${project.title} (Copy)`); // Suggest a new name
    setIsDuplicateDialogOpen(true); // Open the dialog to enter a custom name
  };
  const confirmDuplicateProject = () => {
    if (duplicateProjectName.trim() !== "") {
      DuplicateProject({
        project_id: duplicateProjectId,
        new_title: duplicateProjectName,
      })
        .then((res) => {
          if (res.status >= 200 || res.status <= 299) {
            toast.success("Project duplicated successfully!");
            refreshProjects();
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            toast.error(err.response.data.error);
          } else {
            toast.error("An error occurred!");
            console.log(err.response);
          }
        });
    }
    setDuplicateProjectId(null);
    setDuplicateProjectName("");
    setIsDuplicateDialogOpen(false);
    // Reset dialog state
  };
  const handleAskForDeleteAllProject = () => {
    let deleteRows = [...selectedRows, ...selectedRowsArchive];
    let projectsDeleteRow = projects
      .filter((s) => s.is_owner == true)
      .filter((s) => deleteRows.includes(s.id));
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui"
            style={{ padding: 15, backgroundColor: "white", borderRadius: 10 }}
          >
            <h1>Are you sure?</h1>
            <br />
            <p>
              Are you sure you want to delete projects ?
              {projectsDeleteRow.map((pr) => {
                return (
                  <>
                    <br />- <b>{pr.title}</b>
                  </>
                );
              })}
            </p>
            <br />
            <Button
              onClick={onClose}
              style={{
                textTransform: "none",
                color: "#EB3449",
                border: "1px solid #EB3449",
                marginRight: 10,
              }}
            >
              No
            </Button>
            <Button
              style={{
                textTransform: "none",
                backgroundColor: "#EB3449",
                color: "white",
              }}
              onClick={() => {
                setDeleteAllProjectRows([
                  ...selectedRows,
                  ...selectedRowsArchive,
                ]);
                for (let pr of projectsDeleteRow) {
                  handleDeleteAllProject(pr.id, pr.title);
                }
                onClose();
              }}
            >
              Yes
            </Button>
          </div>
        );
      },
    });
  };
  const handleAskForDeleteProject = (project) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui"
            style={{ padding: 15, backgroundColor: "white", borderRadius: 10 }}
          >
            <h1>Are you sure?</h1>
            <br />
            <p>
              Are you sure you want to delete project <b>{project.title}</b>?
            </p>
            <br />
            <Button
              onClick={onClose}
              style={{
                textTransform: "none",
                color: "#EB3449",
                border: "1px solid #EB3449",
                marginRight: 10,
              }}
            >
              No
            </Button>
            <Button
              style={{
                textTransform: "none",
                backgroundColor: "#EB3449",
                color: "white",
              }}
              onClick={() => {
                onClose();
                handleDeleteProject(project.id);
              }}
            >
              Yes
            </Button>
          </div>
        );
      },
    });
  };

  const handleFilterSelectLabel = (labelid) => {
    if (filterSelectLabel.includes(labelid)) {
      setFilterSelectLabel([...filterSelectLabel.filter((s) => s != labelid)]);
    } else {
      setFilterSelectLabel([...filterSelectLabel, labelid]);
    }
  };

  const handleDownloadAllProjects = () => {
    setDownloadAllProject([...selectedRows, ...selectedRowsArchive]);
    for (let projectId of selectedRows) {
      handleExportProjectFile("latex", projectId);
    }
    for (let projectId of selectedRowsArchive) {
      handleExportProjectFile("latex", projectId);
    }
  };

  const handleExportProjectFile = (type, projectId) => {
    getProject(projectId)
      .then((res) => {
        let IdsList = findReferenceIds(res.data.content);
        let ids = IdsList.length > 0 ? IdsList.toString(0) : "";
        let exportInfo = {
          doc_ids: ids,
          export_type: type,
        };

        exportProject(res.data.id, exportInfo)
          .then((response) => {
            setDownloadAllProject([
              ...downloadAllProject.filter((s) => s != projectId),
            ]);
            if (response.status == 200) {
              if (response.data.message != null) {
                toast.error(response.data.message);
              } else {
                const contentDisposition =
                  response.headers["content-disposition"];
                let filename = res.data.title;
                let headerContentType = response.headers["content-type"];
                let data = response.data;
                DownloadExportFile(
                  type,
                  filename,
                  contentDisposition,
                  headerContentType,
                  data
                );
              }
            } else {
              toast.error(response.data.message);
            }
          })
          .catch((err) => {
            setDownloadAllProject([
              ...downloadAllProject.filter((s) => s != projectId),
            ]);
            if (res.data)
              toast.error("An error occurred on " + res.data.title + "!");
            console.error("Error:", err);
          });
      })
      .catch((err) => {
        setDownloadAllProject([
          ...downloadAllProject.filter((s) => s != projectId),
        ]);
        toast.error("Error !");
      });
  };
  useEffect(() => {
    refreshProjects();
    callApiGetLabel();
  }, []);

  useEffect(() => {
    setShowTagIcon(selectedRows.length > 0);
    setShowTagIconArch(selectedRowsArchive.length > 0);
  }, [selectedRows, selectedRowsArchive]);

  // Function to handle selecting/deselecting all rows
  const handleSelectAllRows = (event) => {
    if (event.target.checked) {
      // If checkbox is checked, select all rows
      const allProjectIds = searchProjects(false).map((project) => project.id);
      setSelectedRows(allProjectIds);
    } else {
      // If checkbox is unchecked, deselect all rows
      setSelectedRows([]);
    }
  };

  // Function to handle selecting/deselecting a single row
  const handleSelectRow = (projectId) => {
    if (selectedRows.includes(projectId)) {
      // If row is already selected, deselect it
      setSelectedRows(selectedRows.filter((id) => id !== projectId));
    } else {
      // If row is not selected, select it
      setSelectedRows([...selectedRows, projectId]);
    }
  };

  const searchProjects = (archived = null) => {
    return projects
      .filter((pr) =>
        pr.title.toLowerCase().includes(searchInput.toLowerCase().trim())
      )
      .filter((pr) => archived == null || pr.archived == archived)
      .filter((pr) => {
        return (
          filterSelectLabel.length == 0 ||
          (pr.labels &&
            pr.is_owner &&
            pr.labels.length > 0 &&
            pr.labels.findIndex((t) => filterSelectLabel.includes(t.id)) >=
              0) ||
          (pr.is_owner == false && filterSelectLabel.includes(0)) ||
          (pr.is_shared == true &&
            pr.is_owner == true &&
            filterSelectLabel.includes(-1))
        );
      });
  };
  const handleSelectAllRowsArchive = (event) => {
    if (event.target.checked) {
      // If checkbox is checked, select all rows in the archive table
      const allProjectIdsArchive = searchProjects(true).map(
        (project) => project.id
      );
      setSelectedRowsArchive(allProjectIdsArchive);
    } else {
      // If checkbox is unchecked, deselect all rows in the archive table
      setSelectedRowsArchive([]);
    }
  };

  const handleSelectRowArchive = (projectId) => {
    if (selectedRowsArchive.includes(projectId)) {
      // If row is already selected, deselect it
      setSelectedRowsArchive(
        selectedRowsArchive.filter((id) => id !== projectId)
      );
    } else {
      // If row is not selected, select it
      setSelectedRowsArchive([...selectedRowsArchive, projectId]);
    }
  };

  return (
    <div className={classes.page}>
      <div className={DocStyles.flexContainer}>
        <CreateNewProjectButton handleClickOpen={handleClickOpen} />
        {/* <LiboBerryFeatures /> */}
      </div>
      <Divider className={DocStyles.divider} />
      <div className={DocStyles.flexContainer2}>
        <div className={DocStyles.SearchPanelBox}>
          <h2 className={classes.sectionTitle} style={{ marginRight: "15px" }}>
            Projects
          </h2>
          <div className={DocStyles.SearchsBox}>
            <TextField
              className={classes.searchInput}
              style={{ width: "100%" }}
              variant="outlined"
              placeholder="Search projects"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    style={{
                      marginRight: "8px",
                      color: "#666",
                    }}
                  />
                ),
              }}
            />
          </div>
        </div>

        <div className={DocStyles.ArchivePanelIconBox}>
          <div className={DocStyles.showTagIconContainer}>
            <div
              onClick={handleOpenMenu}
              title="Label Projects"
              className={DocStyles.labelIconButton}
            >
              <div className={"label_box"}>
                <LabelIcon
                  className={DocStyles.LabelIcon}
                  style={{
                    color: hasOwnerProject() ? "" : "lightgray",
                  }}
                />
                <ArrowDropDownIcon
                  style={{
                    color: hasOwnerProject() ? "" : "lightgray",
                  }}
                  className={DocStyles.ArrowDropDownIcon}
                />
              </div>
            </div>
            <Menu
              style={{
                marginTop: "35px",
                marginRight: "50px",
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                onClick={handleOpenAddTagDialog}
                className={DocStyles.MenuItem}
                style={{ paddingLeft: "8px", paddingRight: "11px" }}
              >
                <div className={DocStyles.MenuItemspan}>
                  Create new Label &nbsp;
                  <AddIcon style={{ fontSize: "1.3em" }} />
                </div>
              </MenuItem>
              {/* Render the tags in menu items */}
              <MenuItem
                key={"search label"}
                onClickCapture={stopImmediatePropagation}
                onKeyDown={(e) => e.stopPropagation()}
                disableGutters={true}
                style={{ backgroundColor: "white" }}
              >
                <div className={DocStyles.searchLabel}>
                  <TextField
                    className={classes.searchInput}
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    placeholder="Search Labels" // Use placeholder instead of label
                    value={searchLabelInput}
                    onChange={(e) => setSearchLabelInput(e.target.value)}
                  />
                </div>
              </MenuItem>
              {tags
                .filter((s) =>
                  s.name
                    .toLowerCase()
                    .includes(searchLabelInput.toLowerCase().trim())
                )
                .map((tag, index) => (
                  <MenuItem
                    key={index}
                    className={DocStyles.menuItem3}
                    // Call handleAddTagClick with the tag name and color
                  >
                    <div
                      onClick={() => {
                        if (
                          selectedRows.length == 0 &&
                          selectedRowsArchive.length == 0
                        ) {
                          toast.info("No projects selected to add label to.");
                          return;
                        }
                        if (isSelectedLabel(tag.id) != "true") {
                          handleAddTagClick(tag.name, tag.color, tag.id);
                        } else {
                          handleRemoveTagClick(tag.id);
                        }
                      }}
                      style={{
                        display: "flex",
                        padding: "5px 0px",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        size="small"
                        className={DocStyles.checkbox}
                        checked={isSelectedLabel(tag.id) == "true"}
                        indeterminate={
                          isSelectedLabel(tag.id) == "indeterminate"
                        }
                      />
                      <div
                        className={DocStyles.tagIcon}
                        style={{ backgroundColor: tag.color }}
                      ></div>
                      <div className={DocStyles.tag} title={tag.name}>
                        <div className="textOverflow">{tag.name}</div>
                      </div>
                    </div>
                    <div>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => {
                          setFilterSelectLabel(
                            filterSelectLabel.filter((s) => s != tag.id)
                          );
                          handleRemoveTagClick(tag.id, true);
                          handleDeleteTags(tag.id);
                        }}
                      >
                        <ClearIcon style={{ fontSize: "0.65em" }} />
                      </IconButton>
                    </div>
                  </MenuItem>
                ))}
            </Menu>
          </div>
          {(showTagIcon || showTagIconArch) && (
            <>
              <div className={DocStyles.divstyle}>
                {showTagIconArch && (
                  <div
                    title="UnArchive Projects"
                    className={DocStyles.ArchiveIconBox}
                    onClick={() => {
                      handleArchiveSelectedProjects(false);
                    }}
                  >
                    <UnarchiveIcon
                      style={{
                        fontSize: "22px",
                      }}
                    />
                  </div>
                )}
                {showTagIcon && (
                  <div
                    title="Archive Projects"
                    className={DocStyles.ArchiveIconBox}
                    onClick={() => {
                      handleArchiveSelectedProjects(true);
                    }}
                  >
                    <ArchiveIcon
                      style={{
                        fontSize: "22px",
                      }}
                    />
                  </div>
                )}

                {[...selectedRows, ...selectedRowsArchive].filter((s) =>
                  pinnedProjects.includes(s)
                ).length > 0 && (
                  <div
                    title="Unpin Projects"
                    className={DocStyles.ArchiveIconBox}
                    onClick={() => {
                      handlePinProject(false);
                    }}
                  >
                    <div style={{ height: "26px", paddingTop: "1px" }}>
                      <UnPin
                        style={{
                          width: "20px",
                        }}
                      />
                    </div>
                  </div>
                )}
                {[...selectedRows, ...selectedRowsArchive].filter(
                  (s) => !pinnedProjects.includes(s)
                ).length > 0 && (
                  <div
                    title="Pin Projects"
                    className={DocStyles.ArchiveIconBox}
                    onClick={() => {
                      handlePinProject(true);
                    }}
                  >
                    <div style={{ height: "26px", paddingTop: "1px" }}>
                      <Pin
                        style={{
                          width: "18px",
                        }}
                      />
                    </div>
                  </div>
                )}
                <div
                  title="Download All Projects"
                  className={DocStyles.ArchiveIconBox}
                  onClick={handleDownloadAllProjects}
                >
                  {downloadAllProject.length == 0 && (
                    <GetAppIcon
                      style={{
                        fontSize: "22px",
                        padding: "2px 0px 0px 0px",
                      }}
                    />
                  )}
                  {downloadAllProject.length != 0 && (
                    <CircularProgress
                      style={{
                        height: "22px",
                        width: "22px",
                      }}
                    />
                  )}
                </div>
                <div
                  title="Delete Projects"
                  className={DocStyles.LeftAppIconBox}
                  onClick={() => {
                    if (hasOwnerProject()) {
                      handleAskForDeleteAllProject();
                    }
                  }}
                >
                  {deleteAllProjectRows.length == 0 && (
                    <DeleteIcon
                      style={{
                        fontSize: "20px",
                        color: hasOwnerProject() ? "" : "lightgray",
                      }}
                    />
                  )}
                  {deleteAllProjectRows.length != 0 && (
                    <CircularProgress
                      style={{ height: "20px", width: "20px" }}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={DocStyles.SearchLabelsBox}>
        {projects.filter((s) => s.is_shared == true && s.is_owner == true)
          .length > 0 && (
          <div
            key={"ShareProjects"}
            className={
              (filterSelectLabel.includes(-1)
                ? DocStyles.SearchLabels_select
                : "") +
              " " +
              DocStyles.div4
            }
            onClick={() => handleFilterSelectLabel(-1)}
            style={{
              backgroundColor: `#60b56054`,
              margin: "5px 5px",
              cursor: "pointer",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className={DocStyles.spancell} title={"Shared projects"}>
              <FolderSharedIcon
                style={{
                  width: "13px",
                  position: "absolute",
                  marginLeft: "-17px",
                  color: "#006100",
                }}
              />
              <div className="textOverflow">Shared projects</div> &nbsp;
              {"(" +
                [...projects].filter(
                  (s) => s.is_shared == true && s.is_owner == true
                ).length +
                ")"}
            </span>
          </div>
        )}
        {projects.filter((s) => s.is_owner == false).length > 0 && (
          <div
            key={"SharedWithMe"}
            className={
              (filterSelectLabel.includes(0)
                ? DocStyles.SearchLabels_select
                : "") +
              " " +
              DocStyles.div4
            }
            onClick={() => handleFilterSelectLabel(0)}
            style={{
              backgroundColor: `#60b56054`,
              margin: "5px 5px",
              cursor: "pointer",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className={DocStyles.spancell} title={"Shared with me"}>
              <GroupIcon
                style={{
                  width: "11px",
                  position: "absolute",
                  marginLeft: "-15px",
                  color: "#006100",
                }}
              />
              <div className="textOverflow">Shared with me</div> &nbsp;
              {"(" +
                [...projects].filter((s) => s.is_owner == false).length +
                ")"}
            </span>
          </div>
        )}

        {tags.map((tag) => (
          <div
            key={tag.name}
            className={
              (filterSelectLabel.includes(tag.id)
                ? DocStyles.SearchLabels_select
                : "") +
              " " +
              DocStyles.div4
            }
            onClick={() => handleFilterSelectLabel(tag.id)}
            style={{
              backgroundColor: `${tag.color + "42"}`,
              margin: "5px 5px",
              cursor: "pointer",
            }}
          >
            <span className={DocStyles.spancell} title={tag.name}>
              <div
                className={DocStyles.div5}
                style={{
                  backgroundColor: `${tag.color}`,
                }}
              ></div>
              <div className="textOverflow">{tag.name}</div> &nbsp;
              {"(" +
                [...projects].filter(
                  (s) =>
                    s.labels != null &&
                    s.labels.length > 0 &&
                    s.labels.findIndex((t) => t.id == tag.id) >= 0
                ).length +
                ")"}
            </span>
          </div>
        ))}
      </div>

      <br />
      {searchProjects(false).length > 0 ? (
        <TableContainer component={Paper} elevation={3}>
          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell className={DocStyles.CheckBoxTableCell}>
                  {/* Checkbox for selecting/deselecting all rows */}
                  <Checkbox
                    onChange={handleSelectAllRows}
                    checked={
                      selectedRows.length === searchProjects(false).length
                    }
                  />
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  Name
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  Type
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  Citation Style
                </TableCell>
                <TableCell align="left">
                  <span style={{ marginRight: "8px", fontWeight: "bold" }}>
                    Date Modified
                  </span>
                  <button
                    onClick={() => handleSortChange("last_modified")}
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    {sortFielddate === "last_modified" &&
                      (sortOrderdate === "asc" ? "▴" : "▾")}
                  </button>
                </TableCell>
                <TableCell align="right" style={{ fontWeight: "bold" }}>
                  <div style={{ marginRight: "30%" }}>Action</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchProjects(false)
                .sort(sortProjectsdate)
                .map((pr) => (
                  <TableRow
                    key={pr.id}
                    className={`${classes.hoverableRow} ${
                      pinnedProjects.includes(pr.id) ? classes.pinnedRow : ""
                    }`}
                  >
                    <TableCell className={DocStyles.CheckBoxTableCell}>
                      {/* Checkbox for selecting/deselecting individual rows */}
                      <Checkbox
                        onChange={() => handleSelectRow(pr.id)}
                        checked={selectedRows.includes(pr.id)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {/* Render project name with label if it has tags */}
                      <div className={DocStyles.div}>
                        <div className={DocStyles.tableTitleBox}>
                          {/* Concatenate tag name with the project name */}
                          <div
                            className={DocStyles.divtwo}
                            onClick={() =>
                              props.history.push("projects/" + pr.id)
                            }
                          >
                            {pr.title}{" "}
                            {!pr.is_owner && (
                              <span key={"SharedWithMe"} title={"Shared"}>
                                <GroupIcon
                                  style={{
                                    width: "14px",
                                    marginBottom: "-8px",
                                    color: "#006100",
                                  }}
                                />
                                &nbsp;
                              </span>
                            )}
                            {pr.is_shared && pr.is_owner && (
                              <span
                                key={"SharedProjects"}
                                title={"Share Project"}
                              >
                                <FolderSharedIcon
                                  style={{
                                    width: "14px",
                                    marginBottom: "-8px",
                                    color: "#006100",
                                  }}
                                />
                                &nbsp;
                              </span>
                            )}
                          </div>
                          &nbsp;
                          {pr.labels && pr.labels.length > 0 && pr.is_owner && (
                            <>
                              {pr.labels.map((tag) => (
                                <div
                                  key={tag.name}
                                  className={DocStyles.div4}
                                  style={{
                                    backgroundColor: `${tag.color + "42"}`,
                                  }}
                                >
                                  <span
                                    className={DocStyles.spancell}
                                    title={tag.name}
                                  >
                                    <div
                                      className={DocStyles.div5}
                                      style={{
                                        backgroundColor: `${tag.color}`,
                                      }}
                                    ></div>

                                    <div className="textOverflow">
                                      {tag.name}{" "}
                                    </div>
                                  </span>
                                  <ClearIcon
                                    style={{
                                      fontSize: "12px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleDeleteTag(pr.id, tag.id)
                                    }
                                  />
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </TableCell>

                    <TableCell align="left">
                      {pr.type.replace(/_/g, " ")}
                    </TableCell>
                    <TableCell align="left">
                      {pr.citation_style?.toUpperCase()}
                    </TableCell>
                    <TableCell align="left">
                      {new Date(pr.last_modified).toLocaleString()}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        title={"Duplicate Project"}
                        onClick={() => {
                          if (pr.is_owner) {
                            handleDuplicateProject(pr);
                          }
                        }}
                      >
                        <CopyIcon
                          color={pr.is_owner ? "#9576b3" : "lightgray"}
                          width="22px"
                          style={{
                            paddingTop: "0px",
                          }}
                        />
                      </IconButton>

                      <IconButton
                        title={"Share Project"}
                        onClick={() => {
                          if (pr.is_owner) {
                            handleOpenShareDialog(pr.id);
                          }
                        }}
                      >
                        <ShareIcon
                          style={{
                            color: pr.is_owner ? "#6d7dd7" : "lightgray",
                          }}
                        />
                      </IconButton>

                      <IconButton
                        title={"Export LaTeX"}
                        onClick={() => {
                          handleExportProjectFile("latex", pr.id);
                        }}
                      >
                        <CloudDownload style={{ color: "#9576b3" }} />
                      </IconButton>
                      <IconButton
                        title={"Export Word"}
                        onClick={() => {
                          handleExportProjectFile("docx", pr.id);
                        }}
                      >
                        <ExtentionIcon
                          type={"docx"}
                          width="22px"
                          style={{ paddingTop: "0px" }}
                        />
                      </IconButton>
                      <IconButton
                        title={"Export PDF"}
                        onClick={() => {
                          handleExportProjectFile("pdf", pr.id);
                        }}
                      >
                        <ExtentionIcon
                          type={"pdf"}
                          width="22px"
                          style={{ paddingTop: "0px" }}
                        />
                      </IconButton>

                      <IconButton
                        title={"Delete Project"}
                        style={{ color: pr.is_owner ? "#f1816d" : "lightgray" }}
                        onClick={() => {
                          if (pr.is_owner) {
                            handleAskForDeleteProject(pr);
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div>You have no projects...</div>
      )}
      <div
        className={DocStyles.ArchivePanelIconBoxArchTable}
        style={{ width: "fit-content", margin: "10px 0px 0px 0px" }}
      >
        <h2
          className={DocStyles.sectionTitle}
          style={{ marginTop: "-2px", marginRight: "14px" }}
        >
          {" "}
          Archived Projects
        </h2>
        <div className={DocStyles.ExpandButton}>
          <FormControlLabel
            control={
              <Switch
                checked={isArchiveTableOpen}
                onChange={toggleArchiveTable}
                name="archiveTableSwitch"
                inputProps={{ "aria-label": "toggle archive table" }}
                title={
                  isArchiveTableOpen
                    ? "Hide Archived Projects"
                    : "Show Archived Projects"
                }
              />
            }
          />
        </div>
      </div>

      <Collapse in={isArchiveTableOpen} timeout="auto" unmountOnExit>
        {isArchiveTableOpen && (
          <div>
            {/* </div> */}
            {/* </h2> */}
            {searchProjects(true).length > 0 ? (
              <TableContainer component={Paper} elevation={3}>
                <Table className={classes.table}>
                  <TableHead className={classes.head}>
                    <TableRow>
                      <TableCell className={DocStyles.CheckBoxTableCell}>
                        {/* Checkbox for selecting/deselecting all rows */}
                        <Checkbox
                          onChange={handleSelectAllRowsArchive}
                          checked={
                            selectedRowsArchive.length ===
                            searchProjects(true).length
                          }
                        />
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Type</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Citation Style
                      </TableCell>
                      <TableCell align="left">
                        <span
                          style={{ marginRight: "8px", fontWeight: "bold" }}
                        >
                          Date Modified
                        </span>
                        <button
                          onClick={() =>
                            handleSortChangeArchive("last_modified")
                          }
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                        >
                          {sortFielddate === "last_modified" &&
                            (sortOrderdate === "asc" ? "▴" : "▾")}
                        </button>
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        <div style={{ marginRight: "125px" }}>Action</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchProjects(true)
                      .sort(sortProjectsdateArchive)
                      .map((project) => (
                        <TableRow
                          key={project.id}
                          className={`${classes.hoverableRow} ${
                            pinnedProjects.includes(project.id)
                              ? classes.pinnedRow
                              : ""
                          }`}
                        >
                          <TableCell className={DocStyles.CheckBoxTableCell}>
                            {/* Checkbox for selecting/deselecting individual rows */}
                            <Checkbox
                              onChange={() =>
                                handleSelectRowArchive(project.id)
                              }
                              checked={selectedRowsArchive.includes(project.id)}
                            />
                          </TableCell>
                          <TableCell>
                            <div className={DocStyles.div}>
                              <div className={DocStyles.tableTitleBox}>
                                {/* Concatenate tag name with the project name */}
                                <div
                                  className={DocStyles.divtwo}
                                  onClick={() =>
                                    props.history.push("projects/" + project.id)
                                  }
                                >
                                  {project.title}{" "}
                                  {!project.is_owner && (
                                    <span key={"SharedWithMe"} title={"Shared"}>
                                      <GroupIcon
                                        style={{
                                          width: "14px",
                                          marginBottom: "-8px",
                                          color: "#006100",
                                        }}
                                      />
                                      &nbsp;
                                    </span>
                                  )}
                                  {project.is_shared && project.is_owner && (
                                    <span
                                      key={"SharedProjects"}
                                      title={"Share Project"}
                                    >
                                      <FolderSharedIcon
                                        style={{
                                          width: "14px",
                                          marginBottom: "-8px",
                                          color: "#006100",
                                        }}
                                      />
                                      &nbsp;
                                    </span>
                                  )}
                                </div>
                                &nbsp;
                                {project.labels &&
                                  project.is_owner &&
                                  project.labels.length > 0 && (
                                    <>
                                      {project.labels.map((tag) => (
                                        <div
                                          key={tag.name}
                                          className={DocStyles.div4}
                                          style={{
                                            backgroundColor: `${
                                              tag.color + "42"
                                            }`,
                                          }}
                                        >
                                          <span
                                            className={DocStyles.spancell}
                                            title={tag.name}
                                          >
                                            <div
                                              className={DocStyles.div5}
                                              style={{
                                                backgroundColor: `${tag.color}`,
                                              }}
                                            ></div>
                                            <div className="textOverflow">
                                              {tag.name}{" "}
                                            </div>
                                            &nbsp;
                                          </span>
                                          <ClearIcon
                                            style={{
                                              fontSize: "12px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleDeleteTag(
                                                project.id,
                                                tag.id
                                              )
                                            }
                                          />
                                        </div>
                                      ))}
                                    </>
                                  )}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>{project.type}</TableCell>
                          <TableCell>
                            {project.citation_style?.toUpperCase()}
                          </TableCell>
                          <TableCell align="left">
                            {new Date(project.last_modified).toLocaleString()}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              title={"Duplicate Project"}
                              onClick={() => {
                                if (project.is_owner) {
                                  handleDuplicateProject(project);
                                }
                              }}
                            >
                              <CopyIcon
                                color={
                                  project.is_owner ? "#9576b3" : "lightgray"
                                }
                                width="22px"
                                style={{
                                  paddingTop: "0px",
                                }}
                              />
                            </IconButton>

                            <IconButton
                              title={"Share Project"}
                              onClick={() => {
                                if (project.is_owner) {
                                  handleOpenShareDialog(project.id);
                                }
                              }}
                            >
                              <ShareIcon
                                style={{
                                  color: project.is_owner
                                    ? "#6d7dd7"
                                    : "lightgray",
                                }}
                              />
                            </IconButton>

                            <IconButton
                              title={"Export LaTeX"}
                              onClick={() => {
                                handleExportProjectFile("latex", project.id);
                              }}
                            >
                              <CloudDownload style={{ color: "#9576b3" }} />
                            </IconButton>
                            <IconButton
                              title={"Export Word"}
                              onClick={() => {
                                handleExportProjectFile("docx", project.id);
                              }}
                            >
                              <ExtentionIcon
                                type={"docx"}
                                width="22px"
                                style={{ paddingTop: "0px" }}
                              />
                            </IconButton>
                            <IconButton
                              title={"Export PDF"}
                              onClick={() => {
                                handleExportProjectFile("pdf", project.id);
                              }}
                            >
                              <ExtentionIcon
                                type={"pdf"}
                                width="22px"
                                style={{ paddingTop: "0px" }}
                              />
                            </IconButton>

                            <IconButton
                              title={"Delete Project"}
                              style={{
                                color: project.is_owner
                                  ? "#f1816d"
                                  : "lightgray",
                              }}
                              onClick={() => {
                                if (project.is_owner) {
                                  handleAskForDeleteProject(project);
                                }
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div>You have no archived projects...</div>
            )}
          </div>
        )}
      </Collapse>
      <DuplicateProjectDialog
        open={isDuplicateDialogOpen}
        onClose={() => setIsDuplicateDialogOpen(false)}
        projectName={duplicateProjectName}
        setProjectName={setDuplicateProjectName}
        onDuplicate={confirmDuplicateProject}
      />
      <NewProjectDialog
        open={newProjectModalIsOpen}
        onClose={handleCloseNewProjectModal}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        newProjectNameRef={newProjectNameRef}
        handleCloseNewProjectModal={handleCloseNewProjectModal}
        refreshProjects={refreshProjects}
      />

      <ShareProjectDialog
        open={isShareDialogOpen}
        project_Id={selectProjectId}
        onClose={handleCloseShareDialog}
        onRefreshShareList={() => {
          handleOpenShareDialog(selectProjectId);
        }}
        refreshProject={refreshProjects}
        shareList={shareList}
      />

      <AddTagDialog
        title="Create New Label"
        open={isAddTagDialogOpen}
        onClose={handleCloseAddTagDialog}
        onCreateTag={handleCreateTag}
        onrefreshtag={callApiGetLabel}
        apiCallSetLabel={setLabel}
        useTagNameProperty={false}
        successMessage={"Create"}
        // onAddTag={handleAddTag} // pass the handleAddTag function
      />
    </div>
  );
}

export default withRouter(DocumentsPage);

DocumentsPage.propTypes = {};
